import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import { connect } from "react-redux";

import { setMessage } from "../../../../Actions/messageActions";
import api from "../../../../Services/Api";

function DialogDeleteDoctor({ doctor, setDoctors }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // handleRemoveDoctor(row);
  };

  const handleDelete = () => {
    api
      .delete(`/appointment_doctors/${doctor.id}`)
      .then((res) => {
        setOpen(false);
        setDoctors(res.data);
        setMessage("Médico solicitante removido com sucesso!", "info");
      })
      .catch((err) => {
        setMessage("Erro ao atualizar o seu perfil!", "error");
      });
  };

  return (
    <>
      <Button type="button" variant="text" onClick={(e) => handleClickOpen()}>
        <DeleteIcon size="small" style={{ color: "#ef5350" }} />
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">
          {"Desvincular Médico"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Você realmente deseja desvincular esse médico?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => handleClose()} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Desvincular
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { setMessage };

export default connect(mapStateToProps, mapDispatchToProps)(DialogDeleteDoctor);
