import { Breadcrumbs, Container, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import isEmpty from "../../../../Helpers/isEmpty";
import api from "../../../../Services/Api";
import { setMessage } from "./../../../../Actions/messageActions";
import Loading from "./../../../../Components/Loading";
import TableUsers from "./TableUsers";

export const Index = ({ setMessage, user, history }) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState({});
  const [roles, setRoles] = useState({});
  const [params, setParams] = useState({
    order: "desc",
    orderBy: "created_at",
    page: 0,
    rowsPerPage: 50,
    total: 0,
    role: 0,
    medicalRecordNumber: "",
    crm: "",
    crmState: "",
    firstName: "",
    lastName: "",
    email: "",
    status: "",
  });

  const getUsers = ({
    pOrder,
    pOrderBy,
    pPage,
    pRowsPerPage,
    pFirstName,
    pLastName,
    pEmail,
    pCrm,
    pCrmState,
    pMedicalRecordNumber,
    pRole,
    pStatus,
  }) => {
    setLoading(true);
    api
      .get("users", {
        params: {
          order: pOrder,
          orderBy: pOrderBy,
          page: pPage,
          rowsPerPage: pRowsPerPage,
          firstName: pFirstName,
          lastName: pLastName,
          email: pEmail,
          crm: pCrm,
          crmState: pCrmState,
          medicalRecordNumber: pMedicalRecordNumber,
          role: pRole,
          status: pStatus,
        },
      })
      .then((res) => {
        if (!isEmpty(res.data)) {
          setUsers(res.data.users);
          setRoles(res.data.roles);
          setParams({
            ...params,
            total: parseInt(res.data.users.total),
            rowsPerPage: res.data.users.perPage,
            page: res.data.users.page - 1,
          });
        } else {
          setMessage("Erro ao fazer a pesquisa, tente novamente!", "error");
        }
        setLoading(false);
      })
      .catch((err) => {
        setMessage("Você não tem permissão para ver o usuários!", "error");
      });
  };

  useEffect(() => {
    setLoading(true);
    if (!isEmpty(user)) {
      api
        .get("users")
        .then((res) => {
          setUsers(res.data.users);
          setRoles(res.data.roles);
          setLoading(false);
          setParams({
            ...params,
            total: parseInt(res.data.users.total),
            page: res.data.users.page - 1,
            rowsPerPage: res.data.users.perPage,
          });
        })
        .catch((err) => {
          setMessage("Você não tem permissão para ver usuários!", "error");
          history.push("/painel");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/painel">Painel</Link>
        <Typography>Usuários</Typography>
      </Breadcrumbs>
      <Container disableGutters>
        <Typography variant="h1">Usuários</Typography>
        <p>Lista de todos os usuários do sistema.</p>
        {loading && <Loading />}
        {!loading && (
          <TableUsers
            users={users}
            roles={roles}
            getUsers={getUsers}
            params={params}
            setParams={setParams}
            history={history}
          />
        )}
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = { setMessage };

export default connect(mapStateToProps, mapDispatchToProps)(Index);
