import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
  margin: {
    margin: "20vh auto 0",
  },
  text: {
    fontWeight: 100,
    fontSize: 16,
    marginTop: 10,
    color: "#ccc",
  },
}));
export default function Loading({ margin = true }) {
  const classes = useStyles();
  let rootClass = classes.root;
  if (margin) {
    rootClass = `${classes.root} ${classes.margin}`;
  }
  return (
    <div className={rootClass}>
      <CircularProgress />
      <div className={classes.text}>Carregando</div>
    </div>
  );
}
