import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { useState } from "react";
import { connect } from "react-redux";

import { setCurrentUser } from "../../../Actions/authActions";
// import { Link } from "react-router-dom";
import api from "../../../Services/Api";
import { setMessage } from "./../../../Actions/messageActions";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    display: "flex",
    height: "100vh",
    background: theme.palette.primary.main,
  },
  errorMessage: {
    fontSize: 14,
    borderRadius: 4,
    marginTop: 10,
    padding: "18px 0px",
    color: "#fff",
    background: "#ca5d5d",
    textAlign: "center",
    position: "absolute",
    width: 380,
  },
  form: {
    margin: "auto",
    maxWidth: 380,
  },
  link: {
    fontSize: 12,
    fontWeight: 300,
    textDecoration: "none",
    color: "#868686",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  linksContainer: {
    textAlign: "right",
    margin: "10px 0px 5px",
  },
  title: {
    fontSize: 22,
    fontWeight: 100,
    margin: "10px auto 20px",
    color: "#464646",
    textAlign: "left",
  },
  textField: {
    margin: "5px auto 10px",
    width: "100%",
    fontSize: 14,
  },
  buttonSubmit: {
    width: "100%",
    color: "#fff",
  },
  paper: {
    padding: "15px 30px 30px",
  },
}));

export const Index = ({
  setCurrentUser,
  isAuthenticated,
  history,
  match,
  setMessage,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage("");
    if (values.password !== values.confirmPassword) {
      setLoading(true);
      setErrorMessage("A senha e confirmação da senha devem ser iguais.");
      setErrors({
        password: "Senhas devem ser iguais!",
        confirmPassword: "Senhas devem ser iguais!",
      });
      setLoading(false);
    } else {
      api
        .post("/auth/change-password", {
          recovery_code: match.params.recovery_code,
          password: values.password,
        })
        .then((res) => {
          setLoading(false);
          setMessage(
            "Senha atualizada com sucesso! Tente fazer seu login.",
            "success"
          );
          history.push("/");
        })
        .catch((err) => {
          let message = "Credenciais inválidas, tente novamente.";
          if (err.response.data.length > 0) {
            message = err.response.data[0].message;
          }
          setLoading(false);
          setErrorMessage(message);
        });
    }
  };

  return (
    <div className={classes.root}>
      <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
        <Paper className={classes.paper} elevation={3}>
          <div style={{ textAlign: "center", width: "100%" }}>
            <img
              src="/img/wave-logo.png"
              style={{ width: "225px", margin: "15px auto 10px" }}
              alt="Wave Medicina Fetal"
            />
          </div>
          <Typography variant="h2" className={classes.title}>
            Criar Nova Senha
          </Typography>
          <p>Preencha os campos para alterar sua senha.</p>
          <TextField
            value={values.password}
            className={classes.textField}
            error={errors.password !== ""}
            id="password"
            label="Nova Senha"
            variant="outlined"
            type={values.showPassword ? "text" : "password"}
            onChange={handleChange("password")}
            disabled={loading}
            classes={{
              root: classes.textField,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            value={values.confirmPassword}
            className={classes.textField}
            error={errors.confirmPassword !== ""}
            id="confirmPassword"
            label="Confirmar Nova Senha"
            variant="outlined"
            type={values.showConfirmPassword ? "text" : "password"}
            onChange={handleChange("confirmPassword")}
            disabled={loading}
            classes={{
              root: classes.textField,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownConfirmPassword}
                  >
                    {values.showConfirmPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            className={classes.buttonSubmit}
            size="large"
          >
            Alterar Senha
          </Button>
        </Paper>
        {errorMessage && (
          <div className={classes.errorMessage}>{errorMessage}</div>
        )}
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = { setCurrentUser, setMessage };

export default connect(mapStateToProps, mapDispatchToProps)(Index);
