import React from "react";
import { Redirect, Route, withRouter } from "react-router-dom";

import AppBar from "./AppBar";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem("token");
  const isAuthenticated = token ? true : false;
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <AppBar>
            <Component {...props} />
          </AppBar>
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};
export default withRouter(PrivateRoute);
