import { Button } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloudQueueIcon from "@material-ui/icons/CloudQueue";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import HelpIcon from "@material-ui/icons/Help";
import clsx from "clsx";
import React, { useState } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { logout } from "./../Actions/authActions";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  title: {
    flexGrow: 1,
    fontWeight: 400,
    fontSize: 16,
    "& b": {
      fontSize: 22,
      marginRight: 6,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function MiniDrawer({ children, logout, user, role }) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            <b>Wave Medicina Fetal</b> resultados on-line
          </Typography>
          <Button component={NavLink} to="/sair" style={{ color: "#e9efed" }}>
            <ExitToAppIcon />
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem
            button
            to="/painel"
            component={NavLink}
            activeClassName="Mui-selected"
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary={"Painel"} />
          </ListItem>
          <ListItem
            button
            component={NavLink}
            activeClassName="Mui-selected"
            to="/perfil"
          >
            <ListItemIcon>
              <AssignmentIndIcon />
            </ListItemIcon>
            <ListItemText primary={"Perfil"} />
          </ListItem>
          <Divider />
          {(role === "client" || role === "doctor") && (
            <>
              <ListItem
                button
                component={NavLink}
                activeClassName="Mui-selected"
                to="/exames"
              >
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary={"Exames"} />
              </ListItem>
            </>
          )}
          {(role === "admin" || role === "employee") && (
            <>
              <ListItem
                button
                component={NavLink}
                activeClassName="Mui-selected"
                to="/exame"
              >
                <ListItemIcon>
                  <AddCircleIcon />
                </ListItemIcon>
                <ListItemText primary={"Novo Exame"} />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                activeClassName="Mui-selected"
                to="/medico"
              >
                <ListItemIcon>
                  <PersonAddIcon />
                </ListItemIcon>
                <ListItemText primary={"Adicionar Médico"} />
              </ListItem>
              {role === "admin" && (
                <>
                  <ListItem
                    button
                    component={NavLink}
                    activeClassName="Mui-selected"
                    to="/circulante"
                  >
                    <ListItemIcon>
                      <PersonAddIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Adicionar Circulante"} />
                  </ListItem>
                  <ListItem
                    button
                    component={NavLink}
                    activeClassName="Mui-selected"
                    to="/administrador"
                  >
                    <ListItemIcon>
                      <PersonAddIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Adicionar Admin"} />
                  </ListItem>
                </>
              )}
              <Divider />
              <ListItem
                button
                component={NavLink}
                activeClassName="Mui-selected"
                to="/arquivos"
              >
                <ListItemIcon>
                  <CloudQueueIcon />
                </ListItemIcon>
                <ListItemText primary={"Fila de Arquivos"} />
              </ListItem>
              <Divider />
              <ListItem
                button
                component={NavLink}
                activeClassName="Mui-selected"
                to="/exames"
              >
                <ListItemIcon>
                  <AllInboxIcon />
                </ListItemIcon>
                <ListItemText primary={"Listar Exames"} />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                activeClassName="Mui-selected"
                to="/usuarios"
              >
                <ListItemIcon>
                  <PeopleAltIcon />
                </ListItemIcon>
                <ListItemText primary={"Listar Usuários"} />
              </ListItem>
            </>
          )}
          {(role === "admin" || role === "employee") && (
            <>
              <Divider />
              <ListItem
                button
                component={NavLink}
                activeClassName="Mui-selected"
                to="/ajuda"
              >
                <ListItemIcon>
                  <HelpIcon />
                </ListItemIcon>
                <ListItemText primary={"Ajuda"} />
              </ListItem>
            </>
          )}
          <Divider />
          <ListItem
            button
            component={NavLink}
            activeClassName="Mui-selected"
            to="/sair"
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={"Sair"} />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  role: state.auth.role.slug,
});

export default connect(mapStateToProps, { logout })(MiniDrawer);
