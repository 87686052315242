import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import React from "react";
import { connect } from "react-redux";

import { setMessage } from "./../../../../Actions/messageActions";
import api from "./../../../../Services/Api";

function DialogDeleteUser({ userId, setMessage, history }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    setLoading(true);
    api
      .delete(`/users/${userId}`)
      .then((res) => {
        setMessage("Usuário removido com sucesso!", "success");
        setLoading(false);
        history.push("/usuarios");
      })
      .catch((err) => {
        setMessage(
          "Você não tem permissão para remover esse usuário!",
          "error"
        );
        setLoading(false);
      });
    setLoading(false);
  };

  return (
    <div>
      <Button
        type="button"
        variant="contained"
        size="large"
        onClick={handleClickOpen}
        style={{ width: "100%", color: "#fff", background: "red" }}
      >
        Deletar
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent style={{ padding: "40px 30px 20px" }}>
          <Typography variant="h3" style={{ fontWeight: 400 }}>
            Você realmente deseja deletar esse usuário?
          </Typography>
          <p style={{ color: "red" }}>
            Saiba que não há como voltar atrás depois dessa ação.
          </p>
        </DialogContent>
        <DialogActions style={{ padding: "0px 30px 25px 0px" }}>
          <Button onClick={handleClose} disabled={loading} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={handleDelete}
            disabled={loading}
            variant="text"
            style={{ color: "red" }}
            autoFocus
          >
            Deletar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { setMessage };

export default connect(mapStateToProps, mapDispatchToProps)(DialogDeleteUser);
