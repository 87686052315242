import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import { connect } from "react-redux";

import api from "../../../../Services/Api";
import { setMessage } from "./../../../../Actions/messageActions";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    marginRight: 10,
    marginBottom: 15,
    display: "inline-block",
  },
  textField: {
    width: "100%",
  },
  buttonSubmit: {
    color: "#fff",
    width: "100%",
  },
  form: {
    margin: "30px auto",
  },
}));

function DialogSearchDoctor({
  setMessage,
  user,
  history,
  doctors,
  setDoctors,
  appointment,
}) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [loadingSearch, setLoadingSearch] = React.useState(false);
  const [users, setUsers] = React.useState({});
  const [values, setValues] = React.useState({
    doctor_id: "",
    crm: "",
    crmState: "",
    firstName: "",
    lastName: "",
    email: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleCleanForm();
  };

  const handleSubmit = () => {
    setLoading(true);
    api
      .get("doctors", {
        params: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          crm: values.crm,
          crmState: values.crmState,
        },
      })
      .then((res) => {
        setUsers(res.data);
        setLoading(false);
        setLoadingSearch(true);
      })
      .catch((err) => {
        setLoading(false);
        setMessage("Erro ao pesquisar médicos!", "error");
      });
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleAddDoctor = (user) => {
    setLoading(true);
    api
      .post("appointment_doctors", {
        appointment_id: appointment.id,
        user_id: user.id,
      })
      .then((res) => {
        setUsers(res.data);
        let users = JSON.parse(JSON.stringify(doctors));
        users.push(res.data);
        setDoctors(users);
        setLoading(false);
        setLoadingSearch(true);
        handleCleanForm();
        handleClose();
        setMessage("Médico vinculado a este prontuário.", "success");
      })
      .catch((err) => {
        setLoading(false);
        setMessage("Erro ao pesquisar médicos!", "error");
      });
  };

  const handleCleanForm = () => {
    setLoadingSearch(false);
    setValues({
      firstName: "",
      lastName: "",
      email: "",
      crm: "",
      crmState: "",
      medicalRecordNumber: "",
      role: 0,
    });
    setUsers([]);
  };

  return (
    <div>
      <Tooltip
        align="right"
        title="Vincular Médico ao Exame"
        onClick={(e) => handleClickOpen()}
      >
        <IconButton>
          <SearchIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography variant="h2">Pesquisar Médicos</Typography>
          <Container disableGutters>
            <p>Filtre sua pesquisa pelos campos abaixo.</p>
            <div className={classes.form}>
              <Grid container spacing={(2, 3)}>
                <Grid item sm={12} md={6}>
                  <TextField
                    value={values.crm}
                    id="crm3"
                    label="CRM"
                    variant="outlined"
                    disabled={loading}
                    onChange={handleChange("crm")}
                    classes={{
                      root: classes.textField,
                    }}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <FormControl variant="outlined" className={classes.textField}>
                    <InputLabel id="crm-state">CRM Estado</InputLabel>
                    <Select
                      labelId="crm-state"
                      id="crm-state-select3"
                      value={values.crmState}
                      disabled={loading}
                      onChange={handleChange("crmState")}
                    >
                      <MenuItem value={"AC"}>Acre</MenuItem>
                      <MenuItem value={"AL"}>Alagoas</MenuItem>
                      <MenuItem value={"AP"}>Amapá</MenuItem>
                      <MenuItem value={"AM"}>Amazonas</MenuItem>
                      <MenuItem value={"BA"}>Bahia</MenuItem>
                      <MenuItem value={"CE"}>Ceará</MenuItem>
                      <MenuItem value={"DF"}>Distrito Federal</MenuItem>
                      <MenuItem value={"ES"}>Espírito Santo</MenuItem>
                      <MenuItem value={"GO"}>Goiás</MenuItem>
                      <MenuItem value={"MA"}>Maranhão</MenuItem>
                      <MenuItem value={"MT"}>Mato Grosso</MenuItem>
                      <MenuItem value={"MS"}>Mato Grosso do Sul</MenuItem>
                      <MenuItem value={"MG"}>Minas Gerais</MenuItem>
                      <MenuItem value={"PA"}>Pará</MenuItem>
                      <MenuItem value={"PB"}>Paraíba</MenuItem>
                      <MenuItem value={"PR"}>Paraná</MenuItem>
                      <MenuItem value={"PE"}>Pernambuco</MenuItem>
                      <MenuItem value={"PI"}>Piauí</MenuItem>
                      <MenuItem value={"RJ"}>Rio de Janeiro</MenuItem>
                      <MenuItem value={"RN"}>Rio Grande do Norte</MenuItem>
                      <MenuItem value={"RS"}>Rio Grande do Sul</MenuItem>
                      <MenuItem value={"RO"}>Rondônia</MenuItem>
                      <MenuItem value={"RR"}>Roraima</MenuItem>
                      <MenuItem value={"SC"}>Santa Catarina</MenuItem>
                      <MenuItem value={"SP"}>São Paulo</MenuItem>
                      <MenuItem value={"SE"}>Sergipe</MenuItem>
                      <MenuItem value={"TO"}>Tocantins</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={6}>
                  <TextField
                    value={values.firstName}
                    id="firstName3"
                    label="Nome"
                    variant="outlined"
                    disabled={loading}
                    onChange={handleChange("firstName")}
                    classes={{
                      root: classes.textField,
                    }}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <TextField
                    value={values.lastName}
                    id="lastName3"
                    label="Sobrenome"
                    variant="outlined"
                    disabled={loading}
                    onChange={handleChange("lastName")}
                    classes={{
                      root: classes.textField,
                    }}
                  />
                </Grid>
                <Grid item sm={12} md={12}>
                  <TextField
                    value={values.email}
                    id="email3"
                    type="email"
                    label="E-mail"
                    variant="outlined"
                    disabled={loading}
                    onChange={handleChange("email")}
                    classes={{
                      root: classes.textField,
                    }}
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <Button
                    type="button"
                    variant="contained"
                    className={classes.buttonSubmit}
                    size="large"
                    onClick={(e) => handleClose()}
                    disabled={loading}
                  >
                    Fechar
                  </Button>
                </Grid>
                <Grid item sm={12} md={4}>
                  <Button
                    variant="contained"
                    className={classes.buttonSubmit}
                    size="large"
                    disabled={loading}
                    onClick={(e) => handleCleanForm()}
                  >
                    Limpar
                  </Button>
                </Grid>
                <Grid item sm={12} md={4}>
                  <Button
                    color="primary"
                    type="button"
                    variant="contained"
                    className={classes.buttonSubmit}
                    size="large"
                    onClick={(e) => handleSubmit()}
                    disabled={loading}
                  >
                    Pesquisar
                  </Button>
                </Grid>
              </Grid>
            </div>
            <Paper className={classes.paper}>
              {users.length === 0 && loadingSearch && (
                <p
                  style={{
                    padding: "30px 30px 30px",
                    fontWeight: 100,
                    textAlign: "center",
                    color: "#bbb",
                    fontSize: 16,
                  }}
                >
                  Não existem médicos para essa pesquisa.
                </p>
              )}
              {users.length > 0 && (
                <>
                  <p
                    style={{
                      padding: "30px 30px 0px",
                      fontWeight: 100,
                      textAlign: "center",
                      color: "#bbb",
                      fontSize: 16,
                    }}
                  >
                    Filtro contém a lista dos últimos 5 médicos dessa pesquisa.
                  </p>
                  <TableContainer>
                    <Table className={classes.table}>
                      <TableBody>
                        {users.map((row, index) => {
                          let disabledButton = false;
                          for (let i = 0; i < doctors.length; i++) {
                            const doctor = doctors[i];
                            if (doctor.user.id === row.id) {
                              disabledButton = true;
                            }
                          }
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                              className={`${classes.tableRow}`}
                            >
                              <TableCell>
                                {`${row.profile.first_name} ${row.profile.last_name}`}
                                <span
                                  style={{
                                    display: "block",
                                    color: "#888888",
                                    fontSize: 14,
                                  }}
                                >{`${row.profile.crm} - ${row.profile.crm_state}`}</span>
                              </TableCell>
                              <TableCell align="right">
                                {disabledButton && (
                                  <Button
                                    type="button"
                                    disabled={disabledButton}
                                    color="primary"
                                    variant="contained"
                                    style={{ color: "#fff" }}
                                  >
                                    Vinculado
                                  </Button>
                                )}
                                {!disabledButton && (
                                  <Button
                                    type="button"
                                    color="primary"
                                    variant="contained"
                                    style={{ color: "#fff" }}
                                    disabled={loading}
                                    onClick={(e) => handleAddDoctor(row)}
                                  >
                                    Vincular
                                  </Button>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </Paper>
          </Container>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { setMessage };

export default connect(mapStateToProps, mapDispatchToProps)(DialogSearchDoctor);
