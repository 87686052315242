import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import api from "../../../../Services/Api";
import { setMessage } from "./../../../../Actions/messageActions";
import PhoneMask from "./../../../../Components/Phone";

const useStyles = makeStyles((theme) => ({
  buttonSubmit: {
    width: "250px",
    margin: "10px auto",
    color: "#fff",
  },
  textField: {
    width: "100%",
  },
  form: {
    margin: "25px auto",
  },
}));

export const CreateMod = ({ setMessage, history }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    sex: "female",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    sex: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setErrors({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      sex: "",
    });
    api
      .post(`/users/employee`, {
        first_name: values.firstName,
        last_name: values.lastName,
        phone: values.phone.trim(),
        email: values.email,
        sex: values.sex,
      })
      .then((res) => {
        setMessage("Circulante adicionada com sucesso!", "success");
        setLoading(false);
        history.push("/usuarios");
      })
      .catch((err) => {
        const data = err.response.data;
        setMessage("Erro ao atualizar o seu perfil!", "error");
        for (let i = 0; i < data.length; i++) {
          if (data[i].field === "first_name") {
            setErrors({ ...errors, firstName: "Campo obrigatório." });
          }
          if (data[i].field === "last_name") {
            setErrors({ ...errors, lastName: "Campo obrigatório." });
          }
          if (data[i].field === "phone") {
            setErrors({ ...errors, phone: "Telefone inválido" });
          }
          if (data[i].field === "email") {
            setErrors({ ...errors, email: "E-mail inválido." });
          }
        }
        setLoading(false);
      });
  };

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/painel">Painel</Link>
        <Link to="/usuarios">Usuários</Link>
        <Typography>Adicionar Circulante</Typography>
      </Breadcrumbs>
      <Container disableGutters>
        <Typography variant="h1">Adicionar Circulante</Typography>
        <p>Informe os dados da circulante para cadastrar no sistema.</p>
        <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
          <Grid container spacing={(2, 3)}>
            <Grid item sm={12} md={6}>
              <TextField
                value={values.firstName}
                error={errors.firstName !== ""}
                helperText={errors.firstName}
                id="firstName"
                label="Nome"
                variant="outlined"
                disabled={loading}
                required
                onChange={handleChange("firstName")}
                classes={{
                  root: classes.textField,
                }}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                value={values.lastName}
                error={errors.lastName !== ""}
                helperText={errors.lastName}
                id="lastName"
                label="Sobrenome"
                variant="outlined"
                disabled={loading}
                required
                onChange={handleChange("lastName")}
                classes={{
                  root: classes.textField,
                }}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                label="Celular"
                value={values.phone}
                helperText={errors.phone}
                error={errors.phone !== ""}
                onChange={handleChange("phone")}
                required
                variant="outlined"
                disabled={loading}
                id="phone"
                InputProps={{
                  inputComponent: PhoneMask,
                }}
                classes={{
                  root: classes.textField,
                }}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                value={values.email}
                error={errors.email !== ""}
                helperText={errors.email}
                id="email"
                type="email"
                label="E-mail"
                variant="outlined"
                disabled={loading}
                required
                onChange={handleChange("email")}
                classes={{
                  root: classes.textField,
                }}
              />
            </Grid>
            <Grid item sm={12} md={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Sexo</FormLabel>
                <RadioGroup
                  aria-label="sex"
                  name="sex"
                  value={values.sex}
                  onChange={handleChange("sex")}
                  disabled={loading}
                >
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Mulher"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Homem"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item sm={12} md={12} style={{ textAlign: "center" }}>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                className={classes.buttonSubmit}
                size="large"
                disabled={loading}
              >
                Adicionar Circulante
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { setMessage };

export default connect(mapStateToProps, mapDispatchToProps)(CreateMod);
