import { Checkbox } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Loading from "../../../../Components/Loading";
import api from "../../../../Services/Api";
import { setUserProfile } from "./../../../../Actions/authActions";
import { setMessage } from "./../../../../Actions/messageActions";
import PhoneMask from "./../../../../Components/Phone";
import TableDoctors from "./TableDoctors";

const useStyles = makeStyles((theme) => ({
  buttonSubmit: {
    margin: "10px auto",
    color: "#fff",
  },
  buttonSearch: {
    color: "#fff",
    width: "100%",
  },
  textField: {
    width: "100%",
  },
  form: {
    margin: "25px auto",
  },
}));

export const Index = ({ role, user, setMessage, setUserProfile, history }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [showFormClient, setShowFormClient] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [loadingMedicalRecord, setLoadingMedicalRecord] = useState(false);
  const [
    disableMedicalRecordNumberField,
    setDisableMedicalRecordNumberField,
  ] = useState(false);

  const [values, setValues] = useState({
    flagDoctorsAllAppointments: "0",
    medicalRecordNumber: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    sex: "female",
    flagUrgent: false,
  });

  const [errors, setErrors] = useState({
    medicalRecordNumber: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    sex: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeChecked = (event) => {
    setValues({ ...values, flagUrgent: event.target.checked });
  };

  const handleSubmitSearch = (event) => {
    event.preventDefault();
    setLoadingMedicalRecord(true);
    api
      .get(`/medical_records/${values.medicalRecordNumber}`)
      .then((res) => {
        if (res.data) {
          setValues({
            ...values,
            medicalRecordNumber: res.data.number,
            firstName: res.data.user.profile.first_name,
            lastName: res.data.user.profile.last_name,
            email: res.data.user.email,
            phone: res.data.user.profile.phone,
            sex: res.data.user.profile.sex,
          });
        } else {
          setValues({
            ...values,
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            sex: "female",
          });
        }
        setDisableMedicalRecordNumberField(true);
        setLoadingMedicalRecord(false);
        setShowFormClient(true);
      })
      .catch((err) => console.log(err));
  };

  const handleCleanForms = () => {
    setDoctors([]);
    setValues({
      flagDoctorsAllAppointments: "0",
      medicalRecordNumber: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      sex: "female",
      flagUrgent: false,
    });
    setErrors({
      medicalRecordNumber: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      sex: "",
    });
    setDisableMedicalRecordNumberField(false);
    setLoading(false);
    setShowFormClient(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setErrors({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      sex: "",
    });
    api
      .post(`/medical_record_downloads`, {
        flag_doctors_all_appointments: values.flagDoctorsAllAppointments,
        medical_record_number: values.medicalRecordNumber,
        first_name: values.firstName,
        last_name: values.lastName,
        phone: values.phone.trim(),
        email: values.email,
        sex: values.sex,
        doctors: doctors,
        flag_urgent: values.flagUrgent,
      })
      .then((res) => {
        setMessage(
          "Exame adicionado na 'Fila de Arquivos' com  com sucesso!",
          "success"
        );
        setLoading(false);
        history.push("/arquivos");
      })
      .catch((err) => {
        const data = err.response.data;
        setMessage("Erro ao atualizar o seu perfil!", "error");
        for (let i = 0; i < data.length; i++) {
          if (data[i].field === "medical_record_number") {
            setErrors({ ...errors, medicalRecordNumber: "Campo obrigatório." });
          }
          if (data[i].field === "first_name") {
            setErrors({ ...errors, fistName: "Campo obrigatório." });
          }
          if (data[i].field === "last_name") {
            setErrors({ ...errors, lastName: "Campo obrigatório." });
          }
          if (data[i].field === "phone") {
            setErrors({ ...errors, phone: "Telefone inválido" });
          }
          if (data[i].field === "email") {
            setErrors({ ...errors, email: "E-mail inválido." });
          }
        }
        let newErrors = {
          medicalRecordNumber: "",
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          sex: "",
          crm: "",
          crmState: "",
        };
        for (let i = 0; i < data.length; i++) {
          if (data[i].field === "medical_record_number") {
            newErrors.medicalRecordNumber = "Campo obrigatório.";
          }
          if (data[i].field === "first_name") {
            newErrors.firstName = "Campo obrigatório.";
          }
          if (data[i].field === "last_name") {
            newErrors.lastName = "Campo obrigatório.";
          }
          if (data[i].field === "phone") {
            newErrors.phone = "Campo obrigatório.";
          }
          if (data[i].field === "email") {
            newErrors.email = "E-mail inválido.";
          }
          if (data[i].field === "crm") {
            newErrors.crm = "Campo obrigatório.";
          }
          if (data[i].field === "crm_state") {
            newErrors.crmState = "Campo obrigatório.";
          }
          setErrors(newErrors);
        }
        setLoading(false);
      });
  };

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/painel">Painel</Link>
        <Link to="/arquivos">Fila de Arquivos</Link>
        <Typography>Novo Exame</Typography>
      </Breadcrumbs>
      <Container disableGutters>
        <Typography variant="h1">Novo Exame</Typography>
        <p>
          Pesquise pelo número do prontuário para adicioná-lo na fila de
          arquivos.
        </p>
        <form className={classes.form} onSubmit={(e) => handleSubmitSearch(e)}>
          <Grid container spacing={(2, 2)}>
            <Grid item sm={12}>
              <TextField
                value={values.medicalRecordNumber}
                error={errors.medicalRecordNumber !== ""}
                helperText={errors.medicalRecordNumber}
                id="medicalRecordNumber"
                label="Prontuário"
                variant="outlined"
                disabled={loading || disableMedicalRecordNumberField}
                type="number"
                required
                onChange={handleChange("medicalRecordNumber")}
                classes={{
                  root: classes.textField,
                }}
              />
            </Grid>
            <Grid item sm={6}>
              <Button
                variant="contained"
                onClick={(e) => {
                  handleCleanForms();
                }}
                className={classes.buttonSearch}
                size="large"
                disabled={loading}
              >
                Limpar
              </Button>
            </Grid>
            <Grid item sm={6}>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                className={classes.buttonSearch}
                size="large"
                disabled={loading}
              >
                Buscar Prontuário
              </Button>
            </Grid>
          </Grid>
        </form>
        {loadingMedicalRecord && <Loading margin={false} />}
        {showFormClient && (
          <>
            <Typography variant="h2">Dados do Cliente</Typography>
            <p>Mantenha os dados do cliente atualizados.</p>
            <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
              <Grid container spacing={(2, 3)}>
                <Grid item sm={12} md={6}>
                  <TextField
                    value={values.firstName}
                    error={errors.firstName !== ""}
                    helperText={errors.firstName}
                    id="firstName"
                    label="Nome"
                    variant="outlined"
                    disabled={loading}
                    required
                    onChange={handleChange("firstName")}
                    classes={{
                      root: classes.textField,
                    }}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <TextField
                    value={values.lastName}
                    error={errors.lastName !== ""}
                    helperText={errors.lastName}
                    id="lastName"
                    label="Sobrenome"
                    variant="outlined"
                    disabled={loading}
                    required
                    onChange={handleChange("lastName")}
                    classes={{
                      root: classes.textField,
                    }}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <TextField
                    label="Celular"
                    value={values.phone}
                    helperText={errors.phone}
                    error={errors.phone !== ""}
                    onChange={handleChange("phone")}
                    required
                    variant="outlined"
                    disabled={loading}
                    id="phone"
                    InputProps={{
                      inputComponent: PhoneMask,
                    }}
                    classes={{
                      root: classes.textField,
                    }}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <TextField
                    value={values.email}
                    error={errors.email !== ""}
                    helperText={errors.email}
                    id="email"
                    type="email"
                    label="E-mail"
                    variant="outlined"
                    disabled={loading}
                    required
                    onChange={handleChange("email")}
                    classes={{
                      root: classes.textField,
                    }}
                  />
                </Grid>
                <Grid item sm={12} md={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Sexo</FormLabel>
                    <RadioGroup
                      aria-label="sex"
                      name="sex"
                      value={values.sex}
                      onChange={handleChange("sex")}
                      disabled={loading}
                    >
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label="Mulher"
                      />
                      <FormControlLabel
                        value="male"
                        control={<Radio />}
                        label="Homem"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={12}>
                  <Paper>
                    <TableDoctors doctors={doctors} setDoctors={setDoctors} />
                  </Paper>
                </Grid>
                {doctors.length > 0 && (
                  <Grid item sm={12} md={12}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="flag_doctors"
                        value={values.flagDoctorsAllAppointments}
                        onChange={handleChange("flagDoctorsAllAppointments")}
                        disabled={loading}
                      >
                        <FormControlLabel
                          value={"0"}
                          control={<Radio />}
                          label="Vincular médicos somente para o último exame dessa paciente."
                        />
                        <FormControlLabel
                          value={"1"}
                          control={<Radio />}
                          label="Vincular médicos para todos os exames anteriores dessa paciente."
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                )}
                <Grid item sm={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.flagUrgent}
                        onChange={handleChangeChecked}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Marcar como URGENTE: prontuário não terá atraso na fila de arquivos."
                  />
                </Grid>
                <Grid item sm={12} md={12} style={{ textAlign: "center" }}>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    className={classes.buttonSubmit}
                    size="large"
                    disabled={loading}
                  >
                    Adicionar na Fila de Arquivos
                  </Button>
                </Grid>
              </Grid>
            </form>
          </>
        )}
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  role: state.auth.role.slug,
  user: state.auth.user,
});

const mapDispatchToProps = { setMessage, setUserProfile };

export default connect(mapStateToProps, mapDispatchToProps)(Index);
