import { Paper, Toolbar } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from "@material-ui/icons/Visibility";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Zoom from "react-medium-image-zoom";
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "./../../../../Components/Loading";

import { setMessage } from "./../../../../Actions/messageActions";
import api from "./../../../../Services/Api";
import AppointmentLog from "./AppointmentLog";
import TableDoctorsUpdate from "./TableDoctorsUpdate";

import "react-medium-image-zoom/dist/styles.css";

const useStyles = makeStyles((theme) => ({
  buttonSubmit: {
    width: "100%",
    color: "#fff",
  },
  download: {
    marginTop: "5px",
    marginRight: "15px",
  },
  textField: {
    width: "100%",
  },
  form: {
    margin: "25px auto",
  },
  roleLabel: {
    color: "#fff",
    padding: "6px 12px",
    margin: "2px",
    borderRadius: 4,
    fontSize: 14,
  },
  roleAdmin: {
    background: "#ffcdd2",
  },
  roleEmployee: {
    background: "#e1bee7",
  },
  roleClient: {
    background: "#f8bbd0",
  },
  roleDoctor: {
    background: "#bbdefb",
  },
  image: {
    width: "100%",
  },
  button: {
    width: "100%",
  },
  title: {
    marginTop: 5,
    marginBottom: 20,
    fontSize: 22,
  },
  link: {
    color: "#fff",
    textDecoration: "none",
  },
  paper: {
    padding: "10px 20px 20px",
  },
  grow: {
    flexGrow: "1",
  },
  noVideos: {
    textAlign: "center",
    margin: "20px auto 30px",
    color: "rgb(204, 204, 204)",
  },
}));

export const Index = ({
  setMessage,
  history,
  match,
  isAuthenticated,
  authRole,
}) => {
  const classes = useStyles();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const [doctors, setDoctors] = useState([]);
  const [noVideos, setNoVideos] = useState(true);

  useEffect(() => {
    if (values.appointmentFiles) {
      for (let index = 0; index < values.appointmentFiles.length; index++) {
        const element = values.appointmentFiles[index];
        if (element.type === "video") {
          setNoVideos(false);
        }
      }
    }
  }, [values.appointmentFiles]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    api
      .put(`/appointments/${match.params.id}`, {
        status: values.status,
      })
      .then((res) => {
        setMessage("Exame atualizado com sucesso!", "success");
        setLoading(false);
        history.push("/exames");
      })
      .catch((err) => {
        setMessage("Erro ao editar exame!", "error");
        setLoading(false);
      });
  };

  const handleChangeFileStatus = (row) => (event) => {
    event.preventDefault();
    setLoading(true);
    let status = "blocked";
    if (row.status === "blocked") {
      status = "active";
    }
    api
      .put(`/appointment_files/${row.id}`, {
        status: status,
      })
      .then((res) => {
        let newAppointmentFiles = [];
        for (let i = 0; i < values.appointmentFiles.length; i++) {
          const appointmentFile = values.appointmentFiles[i];
          if (appointmentFile.id !== row.id) {
            newAppointmentFiles.push(appointmentFile);
          } else {
            newAppointmentFiles.push(res.data);
          }
        }
        setValues({ ...values, appointmentFiles: newAppointmentFiles });
        setMessage("Arquivo atualizado com sucesso!", "success");
        setLoading(false);
      })
      .catch((err) => {
        setMessage("Erro ao editar arquivo!", "error");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true);
      setPageLoaded(false);
      api
        .get(`/appointments/${match.params.id}`)
        .then((res) => {
          setValues(res.data);
          setDoctors(res.data.appointmentDoctors);
          setLoading(false);
          setPageLoaded(true);
        })
        .catch((err) => {
          setMessage("Erro ao pesquisar o exame!", "error");
          history.push("/exames");
        });
    }
  }, [history, isAuthenticated, match.params.id, setMessage]);

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/painel">Painel</Link>
        <Link to="/exames">Exames</Link>
        {authRole !== "client" && authRole !== "doctor" && (
          <Typography>Editar Exame</Typography>
        )}
        {(authRole === "client" || authRole === "doctor") && (
          <Typography>Exame</Typography>
        )}
      </Breadcrumbs>
      <Container disableGutters>
        {authRole !== "client" && authRole !== "doctor" && (
          <>
            <Typography variant="h1">Editar Exame</Typography>
            <p>Atualize os dados do exame.</p>
          </>
        )}
        {!pageLoaded && <Loading />}
        {pageLoaded && (
          <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
            <Grid container spacing={(2, 2)}>
              <Grid item sm={12} md={12}>
                <TableContainer component={Paper}>
                  <Toolbar>
                    <Typography variant="h3">Dados do Exame</Typography>
                  </Toolbar>
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow hover>
                        <TableCell align="left">Data:</TableCell>
                        <TableCell align="right">
                          {moment(values.date).format("DD/MM/YYYY")}
                        </TableCell>
                      </TableRow>
                      {authRole !== "client" && authRole !== "doctor" && (
                        <>
                          <TableRow hover>
                            <TableCell align="left">Status:</TableCell>
                            <TableCell align="right">
                              {values.status === "active"
                                ? "Ativo"
                                : "Bloqueado"}
                            </TableCell>
                          </TableRow>
                          <TableRow hover>
                            <TableCell align="left">Criado em:</TableCell>
                            <TableCell align="right">
                              {moment(values.created_at).format(
                                "DD/MM/YYYY - HH:mm:ss"
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow hover>
                            <TableCell align="left">Atualizado em:</TableCell>
                            <TableCell align="right">
                              {moment(values.updated_at).format(
                                "DD/MM/YYYY - HH:mm:ss"
                              )}
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              {authRole !== "client" && (
                <Grid item sm={12} md={12}>
                  <TableContainer component={Paper}>
                    <Toolbar>
                      <Typography variant="h3">Dados da Paciente</Typography>

                      <div className={classes.grow}></div>
                      {authRole !== "doctor" && (
                        <Button
                          onClick={(e) =>
                            history.push(`/usuarios/${values.user.id}`)
                          }
                        >
                          <VisibilityIcon color="secondary" />
                        </Button>
                      )}
                    </Toolbar>
                    <Table className={classes.table}>
                      <TableBody>
                        <TableRow hover>
                          <TableCell align="left">Prontuário:</TableCell>
                          <TableCell align="right">
                            {values.medicalRecord.number}
                          </TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell align="left">Paciente:</TableCell>
                          <TableCell align="right">
                            {`${values.user.profile.first_name} ${values.user.profile.last_name}`}
                          </TableCell>
                        </TableRow>
                        {authRole !== "doctor" && (
                          <>
                            <TableRow hover>
                              <TableCell align="left">Telefone:</TableCell>
                              <TableCell align="right">
                                {values.user.profile.phone}
                              </TableCell>
                            </TableRow>
                            <TableRow hover>
                              <TableCell align="left">E-mail:</TableCell>
                              <TableCell align="right">
                                {values.user.email}
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                        <TableRow hover>
                          <TableCell align="left">Sexo:</TableCell>
                          <TableCell align="right">
                            {values.user.profile.sex === "female"
                              ? "Mulher"
                              : "Homem"}
                          </TableCell>
                        </TableRow>
                        {authRole !== "doctor" && (
                          <>
                            <TableRow hover>
                              <TableCell align="left">Criado em:</TableCell>
                              <TableCell align="right">
                                {moment(values.user.profile.created_at).format(
                                  "DD/MM/YYYY - HH:mm:ss"
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow hover>
                              <TableCell align="left">Atualizado em:</TableCell>
                              <TableCell align="right">
                                {moment(values.user.profile.created_at).format(
                                  "DD/MM/YYYY - HH:mm:ss"
                                )}
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}

              {authRole !== "client" && authRole !== "doctor" && (
                <Grid item sm={12} md={12}>
                  <Paper>
                    <TableDoctorsUpdate
                      doctors={doctors}
                      setDoctors={setDoctors}
                      appointment={values}
                      history={history}
                    />
                  </Paper>
                </Grid>
              )}
              <Grid item sm={12} md={12}>
                <Paper elevation={2} className={classes.paper}>
                  <Typography variant="h2" className={classes.title}>
                    Laudos
                  </Typography>
                  <Grid container spacing={(2, 2)}>
                    {values.appointmentFiles.map((row, index) => {
                      if (row.type === "pdf") {
                        let status = false;
                        if (row.status === "active") {
                          status = true;
                        }
                        return (
                          <Grid item sm={6} md={4} key={index}>
                            <a href={row.file_url} className={classes.link}>
                              <Button
                                className={classes.button}
                                color="secondary"
                                variant="contained"
                              >
                                Baixar Laudo
                              </Button>
                            </a>
                            {authRole !== "client" && authRole !== "doctor" && (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={status}
                                    onChange={handleChangeFileStatus(row)}
                                  />
                                }
                                label={
                                  row.status === "active"
                                    ? "Desativar"
                                    : "Ativar"
                                }
                              />
                            )}
                          </Grid>
                        );
                      }
                      return "";
                    })}
                  </Grid>
                </Paper>
              </Grid>
              <Grid item sm={12} md={12}>
                <Paper elevation={2} className={classes.paper}>
                  <Typography variant="h2" className={classes.title}>
                    Imagens
                  </Typography>
                  <Grid container spacing={(2, 2)}>
                    {values.appointmentFiles.map((row, index) => {
                      if (row.type === "image") {
                        let status = false;
                        if (row.status === "active") {
                          status = true;
                        }
                        return (
                          <Grid item sm={6} md={4} key={index}>
                            <Zoom>
                              <img
                                src={row.file_url}
                                alt="imagem"
                                className={classes.image}
                              />
                            </Zoom>
                            <Button
                              href={row.file_url}
                              color="secondary"
                              variant="contained"
                              className={classes.download}
                            >
                              baixar
                            </Button>
                            {authRole !== "client" && authRole !== "doctor" && (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={status}
                                    onChange={handleChangeFileStatus(row)}
                                  />
                                }
                                label={
                                  row.status === "active"
                                    ? "Desativar"
                                    : "Ativar"
                                }
                              />
                            )}
                          </Grid>
                        );
                      }
                      return "";
                    })}
                  </Grid>
                </Paper>
              </Grid>
              {authRole !== "doctor" && (
                <Grid item sm={12} md={12}>
                  <Paper elevation={2} className={classes.paper}>
                    <Typography variant="h2" className={classes.title}>
                      Gravações
                    </Typography>
                    <Grid container spacing={(2, 2)}>
                      {noVideos && (
                        <div className={classes.noVideos}>Sem gravações.</div>
                      )}
                      {values.appointmentFiles.map((row, index) => {
                        if (row.type === "video") {
                          let status = false;
                          if (row.status === "active") {
                            status = true;
                          }
                          return (
                            <Grid item sm={6} md={12} key={index}>
                              <ReactPlayer
                                url={row.file_url}
                                width="100%"
                                height="500px"
                                controls={true}
                              />
                              <Button
                                href={row.file_url}
                                color="secondary"
                                variant="contained"
                                className={classes.download}
                              >
                                baixar
                              </Button>
                              {authRole !== "client" &&
                                authRole !== "doctor" && (
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={status}
                                        onChange={handleChangeFileStatus(row)}
                                      />
                                    }
                                    label={
                                      row.status === "active"
                                        ? "Desativar"
                                        : "Ativar"
                                    }
                                  />
                                )}
                            </Grid>
                          );
                        }
                        return "";
                      })}
                    </Grid>
                  </Paper>
                </Grid>
              )}
              {authRole !== "client" && authRole !== "doctor" && (
                <Grid item sm={12} md={12}>
                  <FormControl variant="outlined" className={classes.textField}>
                    <InputLabel id="status">Status *</InputLabel>
                    <Select
                      labelId="status"
                      id="status-select"
                      value={values.status ? values.status : "active"}
                      disabled={loading}
                      onChange={handleChange("status")}
                      required
                    >
                      <MenuItem value={"active"}>Ativo</MenuItem>
                      <MenuItem value={"blocked"}>Bloqueado</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {authRole === "admin" && (
                <Grid item sm={12} md={12}>
                  <AppointmentLog logs={values.appointmentLogs} />
                </Grid>
              )}

              {(authRole === "client" || authRole === "doctor") && (
                <Grid item sm={6} md={12}>
                  <Button
                    type="button"
                    variant="contained"
                    className={classes.buttonSubmit}
                    size="large"
                    disabled={loading}
                    onClick={(e) => {
                      history.goBack();
                    }}
                  >
                    Voltar
                  </Button>
                </Grid>
              )}
              {authRole !== "client" && authRole !== "doctor" && (
                <>
                  <Grid item sm={6} md={6}>
                    <Button
                      type="button"
                      variant="contained"
                      className={classes.buttonSubmit}
                      size="large"
                      disabled={loading}
                      onClick={(e) => {
                        history.goBack();
                      }}
                    >
                      Voltar
                    </Button>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      className={classes.buttonSubmit}
                      size="large"
                      disabled={loading}
                    >
                      Editar Exame
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        )}
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  authRole: state.auth.role.slug,
});

const mapDispatchToProps = { setMessage };

export default connect(mapStateToProps, mapDispatchToProps)(Index);
