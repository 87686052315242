import { Button, Paper } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { setCurrentUser } from "../../../Actions/authActions";
import api from "../../../Services/Api";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    display: "flex",
    height: "100vh",
    textAlign: "center",
    background: theme.palette.primary.main,
  },
  errorMessage: {
    fontSize: 14,
    borderRadius: 4,
    marginTop: 10,
    padding: "18px 0px",
    color: "#fff",
    background: "#ca5d5d",
    position: "absolute",
    width: 380,
  },
  form: {
    margin: "auto",
    maxWidth: 380,
  },
  link: {
    fontSize: 12,
    fontWeight: 300,
    textDecoration: "none",
    color: "#868686",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  linksContainer: {
    textAlign: "right",
    margin: "10px 0px 5px",
  },
  title: {
    fontSize: 22,
    fontWeight: 100,
    margin: "10px auto 20px",
    color: "#464646",
    textAlign: "left",
  },
  textField: {
    margin: "5px auto 10px",
    width: "100%",
    fontSize: 14,
  },
  buttonSubmit: {
    width: "100%",
    color: "#fff",
  },
  paper: {
    padding: "15px 30px",
  },
}));

export const Index = ({ setCurrentUser, isAuthenticated, history }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/painel");
    }
  }, [history, isAuthenticated]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(false);
    setErrorMessage("");
    setLoading(true);
    api
      .post("/auth/login", {
        email: values.email,
        password: values.password,
      })
      .then((res) => {
        setCurrentUser(res.data);
        setLoading(false);
        history.push("/painel");
      })
      .catch((err) => {
        let message = "Credenciais inválidas, tente novamente.";
        setError(true);
        setErrorMessage(message);
        setLoading(false);
      });
  };

  return (
    <div className={classes.root}>
      <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
        <Paper className={classes.paper} elevation={3}>
          <img
            src="/img/wave-logo.png"
            style={{ width: "225px", margin: "15px auto 10px" }}
            alt="Wave Medicina Fetal"
          />
          <p style={{ textAlign: "left" }}>Acesse o resultado de exames:</p>
          <TextField
            value={values.email}
            error={error}
            id="email"
            type="email"
            label="E-mail"
            variant="outlined"
            disabled={loading}
            required
            onChange={handleChange("email")}
            classes={{
              root: classes.textField,
            }}
          />
          <TextField
            value={values.password}
            className={classes.textField}
            error={error}
            id="password"
            label="Senha"
            variant="outlined"
            required
            type={values.showPassword ? "text" : "password"}
            onChange={handleChange("password")}
            disabled={loading}
            classes={{
              root: classes.textField,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            className={classes.buttonSubmit}
            size="large"
          >
            Entrar
          </Button>
          <div className={classes.linksContainer}>
            <Link to="/recuperar-senha" className={classes.link}>
              Perdi minha senha.
            </Link>
          </div>
        </Paper>
        {errorMessage && (
          <div className={classes.errorMessage}>{errorMessage}</div>
        )}
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = { setCurrentUser };

export default connect(mapStateToProps, mapDispatchToProps)(Index);
