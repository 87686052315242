import Fade from "@material-ui/core/Fade";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const SnackBarMessage = ({ message }) => {
  const [state, setState] = useState({
    open: false,
    Transition: Fade,
  });
  const [text, setText] = useState("");
  const [type, setType] = useState("info");

  useEffect(() => {
    setText(message.message);
    setType(message.type);
    setState({ ...state, open: message.display });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };

  return (
    <div>
      <Snackbar
        open={state.open}
        onClose={handleClose}
        TransitionComponent={state.Transition}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        key={state.Transition.name}
        autoHideDuration={5000}
      >
        <Alert onClose={handleClose} severity={type}>
          {text}
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  message: state.message,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SnackBarMessage);
