import { MenuItem, Select, TextField, Tooltip } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import FilterListIcon from "@material-ui/icons/FilterList";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import RefreshIcon from "@material-ui/icons/Refresh";
import WarningIcon from "@material-ui/icons/Warning";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

import api from "../../../../Services/Api";
import isEmpty from "./../../../../Helpers/isEmpty";
import DialogDeleteMedicalRecordDownload from "./DialogDeleteMedicalRecordDownload";

const headCells = [
  {
    label: "",
  },
  {
    id: "status",
    disablePadding: false,
    label: "Status",
  },
  {
    id: "flag_urgent",
    disablePadding: false,
    align: "right",
    label: "Urgente",
  },
  {
    id: "medical_records.number",
    disablePadding: false,
    align: "right",
    label: "Prontuário",
  },
  {
    id: "updated_at",
    align: "right",
    disablePadding: false,
    label: "Atualizado em",
  },
  {
    id: "created_at",
    align: "right",
    disablePadding: false,
    label: "Criado em",
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={`head-${headCell.id || "0"}`}
            align={headCell.align ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useRowStyles = makeStyles({
  root: {
    cursor: "pointer",
    "& > *": {
      borderBottom: "unset",
    },
  },
  statusLabel: {
    padding: "6px 10px",
    borderRadius: 4,
  },
  statusQueue: {
    background: "#fff9c4",
  },
  statusDownloading: {
    background: "#bbdefb",
  },
  statusError: {
    background: "#ffcdd2",
  },
  statusReady: {
    background: "#c8e6c9",
  },
  link: {
    textDecoration: "none",
    color: "#01579b",
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

function Row(props) {
  const { row, handleRefresh } = props;
  const [open, setOpen] = useState(false);
  const [logs, setLogs] = useState([]);
  const classes = useRowStyles();
  let statusClass = "";
  let statusLabel = "";
  if (row.status === "queue") {
    statusLabel = "Na fila";
    statusClass = classes.statusQueue;
  }
  if (row.status === "downloading") {
    statusLabel = "Baixando";
    statusClass = classes.statusDownloading;
  }
  if (row.status === "error") {
    statusLabel = "Erro";
    statusClass = classes.statusError;
  }
  if (row.status === "done") {
    statusLabel = "Pronto";
    statusClass = classes.statusReady;
  }

  const handleShowLogs = (appointment_id) => {
    if (!open) {
      api
        .get(`medical_record_downloads/logs/${appointment_id}`)
        .then((res) => {
          setLogs(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setOpen(!open);
  };
  return (
    <React.Fragment>
      <TableRow
        className={`${classes.root} ${statusClass}`}
        onClick={() => handleShowLogs(row.id)}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">
          <span className={`${classes.statusLabel} ${statusClass}`}>
            {statusLabel}
          </span>
        </TableCell>
        <TableCell align="right">
          {row.flag_urgent ? <WarningIcon color="primary" /> : ""}
        </TableCell>
        <TableCell align="right">{row.medicalRecord.number}</TableCell>
        <TableCell align="right">
          {moment(row.updated_at).format("DD/MM/YYYY - HH:mm:ss")}
        </TableCell>
        <TableCell align="right">
          {moment(row.created_at).format("DD/MM/YYYY - HH:mm:ss")}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div style={{ textAlign: "right" }}>
              <DialogDeleteMedicalRecordDownload
                data={row}
                handleRefresh={handleRefresh}
              />
            </div>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Data</TableCell>
                    <TableCell>Usuário</TableCell>
                    <TableCell>Descrição</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logs &&
                    logs.map((historyRow, index) => (
                      <TableRow key={`logs-${index}`}>
                        <TableCell component="th" scope="row">
                          {historyRow.created_at}
                        </TableCell>
                        <TableCell>
                          <NavLink
                            to={`/usuarios/${historyRow.user.id}`}
                            className={classes.link}
                          >
                            {historyRow.user.profile.first_name}{" "}
                            {historyRow.user.profile.last_name}
                          </NavLink>
                        </TableCell>
                        <TableCell>{historyRow.description}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  legendLabels: {
    margin: "0px 0px 20px",
    "& p": {
      fontSize: 10,
      color: "#ccc",
      margin: "20px 0px 2px 3px",
    },
  },
  roleLabel: {
    padding: "4px 10px",
    margin: "2px",
    borderRadius: 4,
    fontSize: 12,
    color: "#212121",
  },
  roleAdmin: {
    background: "#ffcdd2",
  },
  roleEmployee: {
    background: "#e1bee7",
  },
  roleClient: {
    background: "#f8bbd0",
  },
  roleDoctor: {
    background: "#bbdefb",
  },
  tableRow: {
    cursor: "pointer",
  },
  grow: {
    flexGrow: 1,
  },
  textField: {
    width: "100%",
  },
  buttonSubmit: {
    color: "#fff",
    width: "100%",
  },
  buttonClean: {
    width: "100%",
    color: "#fff",
  },
  form: {
    margin: "30px auto",
  },
  statusBlocked: {
    height: 10,
    width: 10,
    background: "red",
    borderRadius: "50%",
    margin: "auto",
  },
  statusActive: {
    height: 10,
    width: 10,
    background: "green",
    borderRadius: "50%",
    margin: "auto",
  },
  statusLabel: {
    padding: "4px 10px",
    margin: "2px",
    borderRadius: 4,
    fontSize: 12,
    color: "#212121",
  },
  statusQueue: {
    background: "#fff59d",
  },
  statusDownloading: {
    background: "#90caf9",
  },
  statusError: {
    background: "#ef9a9a",
  },
  statusReady: {
    background: "#a5d6a7",
  },
}));

export default function EnhancedTable({
  medicalRecordDownloads,
  getMedicalRecordDownloads,
  params,
  setParams,
}) {
  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [values, setValues] = React.useState({
    medicalRecordNumber: params.medicalRecordNumber,
    doctors: params.doctors,
    status: params.status,
  });

  const handleSearch = useCallback(
    ({
      pOrder,
      pOrderBy,
      pPage,
      pRowsPerPage,
      pMedicalRecordNumber,
      pDoctors,
      pStatus,
    }) => {
      getMedicalRecordDownloads({
        pOrder,
        pOrderBy,
        pPage,
        pRowsPerPage,
        pMedicalRecordNumber,
        pDoctors,
        pStatus,
      });
    },
    [getMedicalRecordDownloads]
  );

  const handleRequestSort = (event, property) => {
    const isAsc = params.orderBy === property && params.order === "asc";
    setParams({ ...params, order: isAsc ? "desc" : "asc", orderBy: property });
    handleSearch({
      pPage: params.page,
      pRowsPerPage: params.rowsPerPage,
      pOrder: isAsc ? "desc" : "asc",
      pOrderBy: property,
      pMedicalRecordNumber: values.medicalRecordNumber,
      pDoctors: values.doctors,
      pStatus: values.status,
    });
  };

  const handleChangePage = (event, newPage) => {
    setParams({ ...params, page: newPage });
    handleSearch({
      pPage: newPage,
      pRowsPerPage: params.rowsPerPage,
      pOrder: params.order,
      pOrderBy: params.orderBy,
      pMedicalRecordNumber: values.medicalRecordNumber,
      pDoctors: values.doctors,
      pStatus: values.status,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setParams({
      ...params,
      rowsPerPage: parseInt(event.target.value),
      page: 0,
    });
    handleSearch({
      pPage: 0,
      pRowsPerPage: parseInt(event.target.value),
      pOrder: params.order,
      pOrderBy: params.orderBy,
      pMedicalRecordNumber: values.medicalRecordNumber,
      pDoctors: values.doctors,
      pStatus: values.status,
    });
  };

  const handleToggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const handleRefresh = () => {
    setLoading(true);
    handleSearch({
      pPage: params.page,
      pRowsPerPage: params.rowsPerPage,
      pOrder: params.order,
      pOrderBy: params.orderBy,
      pMedicalRecordNumber: values.medicalRecordNumber,
      pDoctors: values.doctors,
      pStatus: values.status,
    });
    setLoading(false);
  };

  const handleRefreshDelete = useCallback(() => {
    handleSearch({
      pPage: params.page,
      pRowsPerPage: params.rowsPerPage,
      pOrder: params.order,
      pOrderBy: params.orderBy,
      pMedicalRecordNumber: values.medicalRecordNumber,
      pDoctors: values.doctors,
      pStatus: values.status,
    });
  }, [
    handleSearch,
    params.order,
    params.orderBy,
    params.page,
    params.rowsPerPage,
    values.doctors,
    values.medicalRecordNumber,
    values.status,
  ]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    handleSearch({
      pPage: params.page,
      pRowsPerPage: params.rowsPerPage,
      pOrder: params.order,
      pOrderBy: params.orderBy,
      pMedicalRecordNumber: values.medicalRecordNumber,
      pDoctors: values.doctors,
      pStatus: values.status,
    });
    setLoading(false);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setParams({ ...params, [prop]: event.target.value });
  };

  const handleCleanForm = () => {
    setParams({
      ...params,
      medicalRecordNumber: "",
      doctors: "",
      status: "",
    });
    setValues({
      medicalRecordNumber: "",
      doctors: "",
      status: "",
    });
  };

  useEffect(() => {
    if (!isEmpty(medicalRecordDownloads)) {
      setData(medicalRecordDownloads.data);
    }
  }, [medicalRecordDownloads]);

  // useEffect(() => {
  //   const refreshInterval = setInterval(() => {
  //     handleSearch({
  //       pPage: params.page,
  //       pRowsPerPage: params.rowsPerPage,
  //       pOrder: params.order,
  //       pOrderBy: params.orderBy,
  //       pMedicalRecordNumber: values.medicalRecordNumber,
  //       pDoctors: values.doctors,
  //       pStatus: values.status,
  //     });
  //   }, 3000);
  //   return () => {
  //     clearInterval(refreshInterval);
  //   };
  // }, [
  //   handleRefreshDelete,
  //   handleSearch,
  //   params.order,
  //   params.orderBy,
  //   params.page,
  //   params.rowsPerPage,
  //   values.doctors,
  //   values.medicalRecordNumber,
  //   values.status,
  // ]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar>
          <div className={classes.legendLabels}>
            <p>Legendas:</p>
            <div className={classes.legendLabelsContainer}>
              <span className={`${classes.statusLabel} ${classes.statusQueue}`}>
                na fila
              </span>
              <span
                className={`${classes.statusLabel} ${classes.statusDownloading}`}
              >
                baixando
              </span>
              <span className={`${classes.statusLabel} ${classes.statusError}`}>
                erro
              </span>
              <span className={`${classes.statusLabel} ${classes.statusReady}`}>
                pronto
              </span>
            </div>
          </div>
          <div className={classes.grow}></div>
          <Tooltip
            align="right"
            title="Atualizar Fila"
            onClick={(e) => handleRefresh()}
          >
            <IconButton>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            align="right"
            title="Filtrar Pesquisa"
            onClick={(e) => handleToggleFilter()}
          >
            <IconButton>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
        {openFilter && (
          <Container>
            <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
              <Grid container spacing={(2, 3)}>
                <Grid item sm={12} md={6}>
                  <FormControl variant="outlined" className={classes.textField}>
                    <InputLabel id="status">Status</InputLabel>
                    <Select
                      labelId="status"
                      id="status-select"
                      value={values.status}
                      disabled={loading}
                      onChange={handleChange("status")}
                    >
                      <MenuItem value={""}>--</MenuItem>
                      <MenuItem value={"downloading"}>Baixando</MenuItem>
                      <MenuItem value={"error"}>Erro</MenuItem>
                      <MenuItem value={"done"}>Pronto</MenuItem>
                      <MenuItem value={"queue"}>Na Fila</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={6}>
                  <TextField
                    value={values.medicalRecordNumber}
                    id="medicalRecordNumber"
                    label="Prontuário"
                    variant="outlined"
                    disabled={loading}
                    type="number"
                    onChange={handleChange("medicalRecordNumber")}
                    classes={{
                      root: classes.textField,
                    }}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <Button
                    variant="contained"
                    className={classes.buttonClean}
                    size="large"
                    disabled={loading}
                    onClick={(e) => handleCleanForm()}
                  >
                    Limpar
                  </Button>
                </Grid>
                <Grid item sm={12} md={6}>
                  <Button
                    color="secondary"
                    type="submit"
                    variant="contained"
                    className={classes.buttonSubmit}
                    size="large"
                    disabled={loading}
                  >
                    Pesquisar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        )}
        <TableContainer>
          <Table className={classes.table}>
            <EnhancedTableHead
              classes={classes}
              order={params.order}
              orderBy={params.orderBy}
              onRequestSort={handleRequestSort}
              rowCount={params.total}
            />
            <TableBody>
              {data.map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  handleRefresh={handleRefreshDelete}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          component="div"
          count={params.total}
          rowsPerPage={params.rowsPerPage}
          page={params.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) =>
            `${from} - ${to} de ${count}`
          }
          labelRowsPerPage="Por página"
        />
      </Paper>
    </div>
  );
}
