import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";

import SnackBarMessage from "./Components/SnackBarMessage";
import history from "./Helpers/history";
import Routes from "./routes";
import store from "./store";

const useStyles = makeStyles((theme) => ({
  app: {
    minWidth: "320px",
    fontFamily: "Roboto",
    fontWeight: 300,
    minHeight: "100vh",
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#a0bbb5",
    },
    secondary: {
      main: "#717275",
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        fontSize: 18,
      },
      filled: {
        color: "#a0bbb5",
      },
    },
    MuiOutlinedInput: {
      input: {
        fontSize: 16,
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: "#737373",
        "&:hover": {
          backgroundColor: "#444",
        },
      },
      label: {
        textTransform: "none",
      },
    },
    MuiInputBase: {
      // input: {
      //   "&:-webkit-autofill": {
      //     WebkitBoxShadow: "0 0 0 1000px white inset",
      //     backgroundColor: "red !important",
      //   },
      // },
    },
    MuiToolbar: {
      regular: {
        color: "#fff",
      },
    },
    MuiDrawer: {
      paper: {
        background: "#a0bbb5",
        color: "#fff",
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: 100,
      },
    },
    MuiListItemIcon: {
      root: {
        color: "#e9efed",
      },
    },
    MuiSvgIcon: {
      root: {
        // color: "#e9efed",
      },
    },
    MuiTypography: {
      h1: {
        color: "#717275",
        fontSize: 36,
        fontWeight: 100,
      },
      h2: {
        color: "#717275",
        fontSize: 26,
        fontWeight: 100,
      },
      h3: {
        color: "#717275",
        fontSize: 22,
        fontWeight: 100,
      },
    },
    MuiBreadcrumbs: {
      root: {
        marginBottom: 24,
      },
      li: {
        "& p": {
          fontWeight: 100,
        },
        "& a": {
          color: "#a0bbb5",
          fontWeight: 100,
          textDecoration: "none",
        },
      },
    },
    MuiFormGroup: {
      root: {
        marginTop: 10,
      },
    },
    MuiFormControlLabel: {
      label: {
        color: "#717275",
        fontSize: 18,
      },
    },
    MuiTablePagination: {
      toolbar: {
        color: "#a0bbb5",
      },
      caption: {
        fontWeight: 100,
      },
      selectRoot: {
        paddingRight: 8,
      },
    },
    MuiTableCell: {
      root: {
        fontSize: 16,
        fontWeight: 100,
      },
      head: {
        fontWeight: 400,
      },
    },
    MuiDialogContent: {
      root: {
        padding: "12px 24px",
      },
    },
  },
});

function App() {
  const classes = useStyles();

  const startAnalytics = () => {
    ReactGA.initialize("UA-178460154-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  };

  useEffect(() => {
    startAnalytics();
  }, []);

  return (
    <div className={classes.app}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <HelmetProvider>
            <Helmet>
              <title>Resultados de Exames | Wave Medicina Fetal</title>
              <meta
                name="description"
                content="Wave Medicina Fetal - Resultados de exames online."
              />
              <meta
                name="keywords"
                content="wave medicina fetal, ecografias, resultado de exames"
              />
            </Helmet>
            <CssBaseline />
            <SnackBarMessage />
            <Router history={history}>
              <Routes />
            </Router>
          </HelmetProvider>
        </Provider>
      </ThemeProvider>
    </div>
  );
}
export default App;
