import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from "@material-ui/icons/FilterList";
import moment from "moment";
import React, { useEffect } from "react";

import isEmpty from "./../../../../Helpers/isEmpty";

const headCells = [
  {
    id: "profiles.first_name",
    disablePadding: false,
    label: "Nome",
  },
  {
    id: "profiles.last_name",
    disablePadding: false,
    label: "Sobrenome",
  },
  {
    id: "email",
    disablePadding: false,
    label: "E-mail",
  },
  {
    id: "status",
    align: "left",
    disablePadding: false,
    label: "Status",
  },
  {
    id: "created_at",
    align: "right",
    disablePadding: false,
    label: "Criado em",
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  legendLabels: {
    margin: "0px 0px 20px",
    "& p": {
      fontSize: 10,
      color: "#ccc",
      margin: "20px 0px 2px 3px",
    },
  },
  roleLabel: {
    padding: "4px 10px",
    margin: "2px",
    borderRadius: 4,
    fontSize: 12,
    color: "#212121",
  },
  roleAdmin: {
    background: "#ffcdd2",
  },
  roleEmployee: {
    background: "#e1bee7",
  },
  roleClient: {
    background: "#f8bbd0",
  },
  roleDoctor: {
    background: "#bbdefb",
  },
  tableRow: {
    cursor: "pointer",
  },
  grow: {
    flexGrow: 1,
  },
  textField: {
    width: "100%",
  },
  buttonSubmit: {
    color: "#fff",
    width: "100%",
  },
  buttonClean: {
    width: "100%",
    color: "#fff",
  },
  form: {
    margin: "30px auto",
  },
  statusBlocked: {
    height: 10,
    width: 10,
    background: "red",
    borderRadius: "50%",
    margin: "auto",
  },
  statusActive: {
    height: 10,
    width: 10,
    background: "green",
    borderRadius: "50%",
    margin: "auto",
  },
}));

export default function EnhancedTable({
  users,
  roles,
  getUsers,
  params,
  setParams,
  history,
}) {
  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [currentRole, setCurrentRole] = React.useState("");
  const [values, setValues] = React.useState({
    role: params.role,
    medicalRecordNumber: params.medicalRecordNumber,
    crm: params.crm,
    crmState: params.crmState,
    firstName: params.firstName,
    lastName: params.lastName,
    email: params.email,
    status: params.status,
  });

  const handleRequestSort = (event, property) => {
    const isAsc = params.orderBy === property && params.order === "asc";
    setParams({ ...params, order: isAsc ? "desc" : "asc", orderBy: property });
    getUsers({
      pPage: params.page,
      pRowsPerPage: params.rowsPerPage,
      pOrder: isAsc ? "desc" : "asc",
      pOrderBy: property,
      pFirstName: values.firstName,
      pLastName: values.lastName,
      pEmail: values.email,
      pCrm: values.crm,
      pCrmState: values.crmState,
      pMedicalRecordNumber: values.medicalRecordNumber,
      pRole: values.role,
      pStatus: values.status,
    });
  };

  const handleChangePage = (event, newPage) => {
    setParams({ ...params, page: newPage });
    getUsers({
      pPage: newPage,
      pRowsPerPage: params.rowsPerPage,
      pOrder: params.order,
      pOrderBy: params.orderBy,
      pFirstName: values.firstName,
      pLastName: values.lastName,
      pEmail: values.email,
      pCrm: values.crm,
      pCrmState: values.crmState,
      pMedicalRecordNumber: values.medicalRecordNumber,
      pRole: values.role,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setParams({
      ...params,
      rowsPerPage: parseInt(event.target.value),
      page: 0,
    });
    getUsers({
      pPage: 0,
      pRowsPerPage: parseInt(event.target.value),
      pOrder: params.order,
      pOrderBy: params.orderBy,
      pFirstName: values.firstName,
      pLastName: values.lastName,
      pEmail: values.email,
      pCrm: values.crm,
      pCrmState: values.crmState,
      pMedicalRecordNumber: values.medicalRecordNumber,
      pRole: values.role,
    });
  };

  const handleToggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    getUsers({
      pPage: params.page,
      pRowsPerPage: params.rowsPerPage,
      pOrder: params.order,
      pOrderBy: params.orderBy,
      pFirstName: params.firstName,
      pLastName: params.lastName,
      pEmail: params.email,
      pCrm: params.crm,
      pCrmState: params.crmState,
      pMedicalRecordNumber: params.medicalRecordNumber,
      pRole: params.role,
    });
    setLoading(false);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setParams({ ...params, [prop]: event.target.value });
  };

  const handleCleanForm = () => {
    setParams({
      ...params,
      firstName: "",
      lastName: "",
      email: "",
      crm: "",
      crmState: "",
      medicalRecordNumber: "",
      role: 0,
      status: "",
    });
    setValues({
      firstName: "",
      lastName: "",
      email: "",
      crm: "",
      crmState: "",
      medicalRecordNumber: "",
      role: 0,
      status: "",
    });
  };

  useEffect(() => {
    if (!isEmpty(users)) {
      setData(users.data);
    }
  }, [users]);

  useEffect(() => {
    if (values.role) {
      setCurrentRole(values.role);
      if (values.role === "client") {
        setValues({ ...values, crm: "", crmState: "" });
        setParams({ ...params, crm: "", crmState: "" });
      } else if (values.role === "doctor") {
        setValues({ ...values, medicalRecordNumber: "" });
        setParams({ ...params, medicalRecordNumber: "" });
      } else {
        setValues({
          ...values,
          crm: "",
          crmState: "",
          medicalRecordNumber: "",
        });
        setParams({
          ...params,
          crm: "",
          crmState: "",
          medicalRecordNumber: "",
        });
      }
    } else {
      setCurrentRole("");
      setValues({
        ...values,
        crm: "",
        crmState: "",
        medicalRecordNumber: "",
      });
      setParams({
        ...params,
        crm: "",
        crmState: "",
        medicalRecordNumber: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles, values.role]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar>
          <div className={classes.legendLabels}>
            <p>Legendas:</p>
            <div className={classes.legendLabelsContainer}>
              <span className={`${classes.roleLabel} ${classes.roleAdmin}`}>
                Administrador
              </span>
              <span className={`${classes.roleLabel} ${classes.roleEmployee}`}>
                Circulante
              </span>
              <span className={`${classes.roleLabel} ${classes.roleClient}`}>
                Paciente
              </span>
              <span className={`${classes.roleLabel} ${classes.roleDoctor}`}>
                Médico
              </span>
            </div>
          </div>
          <div className={classes.grow}></div>
          <Tooltip
            align="right"
            title="Filtrar Pesquisa"
            onClick={(e) => handleToggleFilter()}
          >
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
        {openFilter && (
          <Container>
            <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
              {!isEmpty(roles) && (
                <>
                  <Grid container spacing={(2, 3)}>
                    <Grid item sm={12} md={4}>
                      <FormControl
                        variant="outlined"
                        className={classes.textField}
                      >
                        <InputLabel id="role">Tipo de Usuário</InputLabel>
                        <Select
                          labelId="role"
                          id="role-select"
                          value={values.role}
                          disabled={loading}
                          onChange={handleChange("role")}
                        >
                          <MenuItem key={0} value={0}>
                            --
                          </MenuItem>
                          {roles.map((row, index) => (
                            <MenuItem key={index + 1} value={row.slug}>
                              {row.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={4}>
                      <FormControl
                        variant="outlined"
                        className={classes.textField}
                      >
                        <InputLabel id="status-select-label">Status</InputLabel>
                        <Select
                          labelId="status-select-label"
                          id="status-select"
                          value={values.status}
                          disabled={loading}
                          onChange={handleChange("status")}
                        >
                          <MenuItem value={""}>--</MenuItem>
                          <MenuItem value={"active"}>Ativo</MenuItem>
                          <MenuItem value={"blocked"}>Bloqueado</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} md={4}>
                      <TextField
                        value={values.firstName}
                        id="firstName"
                        label="Nome"
                        variant="outlined"
                        disabled={loading}
                        onChange={handleChange("firstName")}
                        classes={{
                          root: classes.textField,
                        }}
                      />
                    </Grid>
                    <Grid item sm={12} md={4}>
                      <TextField
                        value={values.lastName}
                        id="lastName"
                        label="Sobrenome"
                        variant="outlined"
                        disabled={loading}
                        onChange={handleChange("lastName")}
                        classes={{
                          root: classes.textField,
                        }}
                      />
                    </Grid>
                    <Grid item sm={12} md={4}>
                      <TextField
                        value={values.email}
                        id="email"
                        type="email"
                        label="E-mail"
                        variant="outlined"
                        disabled={loading}
                        onChange={handleChange("email")}
                        classes={{
                          root: classes.textField,
                        }}
                      />
                    </Grid>
                    {currentRole === "client" && (
                      <Grid item sm={12} md={4}>
                        <TextField
                          value={values.medicalRecordNumber}
                          id="medicalRecordNumber"
                          label="Prontuário"
                          variant="outlined"
                          disabled={loading}
                          type="number"
                          onChange={handleChange("medicalRecordNumber")}
                          classes={{
                            root: classes.textField,
                          }}
                        />
                      </Grid>
                    )}
                    {currentRole === "doctor" && (
                      <>
                        <Grid item sm={12} md={4}>
                          <TextField
                            value={values.crm}
                            id="crm"
                            label="CRM"
                            variant="outlined"
                            disabled={loading}
                            onChange={handleChange("crm")}
                            classes={{
                              root: classes.textField,
                            }}
                          />
                        </Grid>
                        <Grid item sm={12} md={4}>
                          <FormControl
                            variant="outlined"
                            className={classes.textField}
                          >
                            <InputLabel id="crm-state">CRM Estado</InputLabel>
                            <Select
                              labelId="crm-state"
                              id="crm-state-select"
                              value={values.crmState}
                              disabled={loading}
                              onChange={handleChange("crmState")}
                            >
                              <MenuItem value={"AC"}>Acre</MenuItem>
                              <MenuItem value={"AL"}>Alagoas</MenuItem>
                              <MenuItem value={"AP"}>Amapá</MenuItem>
                              <MenuItem value={"AM"}>Amazonas</MenuItem>
                              <MenuItem value={"BA"}>Bahia</MenuItem>
                              <MenuItem value={"CE"}>Ceará</MenuItem>
                              <MenuItem value={"DF"}>Distrito Federal</MenuItem>
                              <MenuItem value={"ES"}>Espírito Santo</MenuItem>
                              <MenuItem value={"GO"}>Goiás</MenuItem>
                              <MenuItem value={"MA"}>Maranhão</MenuItem>
                              <MenuItem value={"MT"}>Mato Grosso</MenuItem>
                              <MenuItem value={"MS"}>
                                Mato Grosso do Sul
                              </MenuItem>
                              <MenuItem value={"MG"}>Minas Gerais</MenuItem>
                              <MenuItem value={"PA"}>Pará</MenuItem>
                              <MenuItem value={"PB"}>Paraíba</MenuItem>
                              <MenuItem value={"PR"}>Paraná</MenuItem>
                              <MenuItem value={"PE"}>Pernambuco</MenuItem>
                              <MenuItem value={"PI"}>Piauí</MenuItem>
                              <MenuItem value={"RJ"}>Rio de Janeiro</MenuItem>
                              <MenuItem value={"RN"}>
                                Rio Grande do Norte
                              </MenuItem>
                              <MenuItem value={"RS"}>
                                Rio Grande do Sul
                              </MenuItem>
                              <MenuItem value={"RO"}>Rondônia</MenuItem>
                              <MenuItem value={"RR"}>Roraima</MenuItem>
                              <MenuItem value={"SC"}>Santa Catarina</MenuItem>
                              <MenuItem value={"SP"}>São Paulo</MenuItem>
                              <MenuItem value={"SE"}>Sergipe</MenuItem>
                              <MenuItem value={"TO"}>Tocantins</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid container spacing={(2, 3)}>
                    <Grid item sm={12} md={6}>
                      <Button
                        variant="contained"
                        className={classes.buttonClean}
                        size="large"
                        disabled={loading}
                        onClick={(e) => handleCleanForm()}
                      >
                        Limpar
                      </Button>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <Button
                        color="secondary"
                        type="submit"
                        variant="contained"
                        className={classes.buttonSubmit}
                        size="large"
                        disabled={loading}
                      >
                        Pesquisar
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </form>
          </Container>
        )}
        <TableContainer>
          <Table className={classes.table}>
            <EnhancedTableHead
              classes={classes}
              order={params.order}
              orderBy={params.orderBy}
              onRequestSort={handleRequestSort}
              rowCount={params.total}
            />
            <TableBody>
              {data.map((row, index) => {
                let roleClass = "";
                if (row.roleUser.role.slug === "admin") {
                  roleClass = classes.roleAdmin;
                }
                if (row.roleUser.role.slug === "employee") {
                  roleClass = classes.roleEmployee;
                }
                if (row.roleUser.role.slug === "client") {
                  roleClass = classes.roleClient;
                }
                if (row.roleUser.role.slug === "doctor") {
                  roleClass = classes.roleDoctor;
                }
                let statusClass = "";
                if (row.status === "active") {
                  statusClass = classes.statusActive;
                }
                if (row.status === "blocked") {
                  statusClass = classes.statusBlocked;
                }
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    className={`${classes.tableRow} ${roleClass}`}
                    onClick={(e) => history.push(`/usuarios/${row.id}`)}
                    style={{ textDecoration: "none" }}
                  >
                    <TableCell>{row.profile.first_name}</TableCell>
                    <TableCell>{row.profile.last_name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell align="right">
                      <div className={`${statusClass}`} />
                    </TableCell>
                    <TableCell align="right">
                      {moment(row.created_at).format("DD/MM/YYYY - HH:mm:ss")}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          component="div"
          count={params.total}
          rowsPerPage={params.rowsPerPage}
          page={params.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) =>
            `${from} - ${to} de ${count}`
          }
          labelRowsPerPage="Por página"
        />
      </Paper>
    </div>
  );
}
