import api from "../../../../Services/Api";
import {
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import React, { useState } from "react";
import { connect } from "react-redux";

import { setMessage } from "./../../../../Actions/messageActions";
import PhoneMask from "./../../../../Components/Phone";

const useStyles = makeStyles((theme) => ({
  buttonSubmit: {
    margin: "10px auto",
    color: "#fff",
    width: "100%",
  },
  textField: {
    width: "100%",
  },
  form: {
    margin: "25px auto",
  },
  root: {
    "& p": {
      fontWeight: 100,
      color: "#ccc",
    },
  },
}));

function DialogNewDoctor({ sendMessage, history, setDoctors, doctors }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    sex: "female",
    crm: "",
    crmState: "PR",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    sex: "",
    crm: "",
    crmState: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmitNewDoctor = () => {
    setLoading(true);
    setErrors({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      sex: "",
      crm: "",
      crmState: "",
    });
    api
      .post(`/users/doctor`, {
        first_name: values.firstName,
        last_name: values.lastName,
        phone: values.phone.trim(),
        email: values.email,
        sex: values.sex,
        crm: values.crm,
        crm_state: values.crmState,
      })
      .then((res) => {
        setMessage("Médico adicionado com sucesso!", "success");
        setLoading(false);

        let users = JSON.parse(JSON.stringify(doctors));
        users.push(res.data);
        setDoctors(users);
        handleClose();
        handleCleanForm();
      })
      .catch((err) => {
        const data = err.response.data;
        setMessage("Erro ao atualizar o seu perfil!", "error");
        let newErrors = {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          sex: "",
          crm: "",
          crmState: "",
        };
        for (let i = 0; i < data.length; i++) {
          if (data[i].field === "first_name") {
            newErrors.firstName = "Campo obrigatório.";
          }
          if (data[i].field === "last_name") {
            newErrors.lastName = "Campo obrigatório.";
          }
          if (data[i].field === "phone") {
            newErrors.phone = "Campo obrigatório.";
          }
          if (data[i].field === "email") {
            newErrors.email = "E-mail inválido.";
          }
          if (data[i].field === "crm") {
            newErrors.crm = "Campo obrigatório.";
          }
          if (data[i].field === "crm_state") {
            newErrors.crmState = "Campo obrigatório.";
          }
          setErrors(newErrors);
        }
        setLoading(false);
      });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setErrors({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      sex: "",
      crm: "",
      crmState: "",
    });
  };
  const handleCleanForm = () => {
    setValues({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      sex: "female",
      crm: "",
      crmState: "PR",
    });
    setErrors({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      sex: "",
      crm: "",
      crmState: "",
    });
  };

  return (
    <div className={classes.root}>
      <Tooltip
        align="right"
        title="Registrar novo Médico"
        onClick={(e) => handleClickOpen()}
      >
        <IconButton>
          <PersonAddIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography variant="h2">Adicionar novo Médico</Typography>
          <div>
            <Container disableGutters>
              <p>Informe os dados para cadastrar novo médico solicitante.</p>
              <div className={classes.form}>
                <Grid container spacing={(2, 3)}>
                  <Grid item sm={12} md={6}>
                    <TextField
                      value={values.crm}
                      error={errors.crm !== ""}
                      helperText={errors.crm}
                      id="crm"
                      label="CRM"
                      variant="outlined"
                      disabled={loading}
                      required
                      onChange={handleChange("crm")}
                      classes={{
                        root: classes.textField,
                      }}
                    />
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormControl
                      variant="outlined"
                      className={classes.textField}
                    >
                      <InputLabel id="crm-state">CRM Estado *</InputLabel>
                      <Select
                        labelId="crm-state"
                        id="crm-state-select"
                        value={values.crmState}
                        error={errors.crmState !== ""}
                        disabled={loading}
                        onChange={handleChange("crmState")}
                        required
                      >
                        <MenuItem value={"AC"}>Acre</MenuItem>
                        <MenuItem value={"AL"}>Alagoas</MenuItem>
                        <MenuItem value={"AP"}>Amapá</MenuItem>
                        <MenuItem value={"AM"}>Amazonas</MenuItem>
                        <MenuItem value={"BA"}>Bahia</MenuItem>
                        <MenuItem value={"CE"}>Ceará</MenuItem>
                        <MenuItem value={"DF"}>Distrito Federal</MenuItem>
                        <MenuItem value={"ES"}>Espírito Santo</MenuItem>
                        <MenuItem value={"GO"}>Goiás</MenuItem>
                        <MenuItem value={"MA"}>Maranhão</MenuItem>
                        <MenuItem value={"MT"}>Mato Grosso</MenuItem>
                        <MenuItem value={"MS"}>Mato Grosso do Sul</MenuItem>
                        <MenuItem value={"MG"}>Minas Gerais</MenuItem>
                        <MenuItem value={"PA"}>Pará</MenuItem>
                        <MenuItem value={"PB"}>Paraíba</MenuItem>
                        <MenuItem value={"PR"}>Paraná</MenuItem>
                        <MenuItem value={"PE"}>Pernambuco</MenuItem>
                        <MenuItem value={"PI"}>Piauí</MenuItem>
                        <MenuItem value={"RJ"}>Rio de Janeiro</MenuItem>
                        <MenuItem value={"RN"}>Rio Grande do Norte</MenuItem>
                        <MenuItem value={"RS"}>Rio Grande do Sul</MenuItem>
                        <MenuItem value={"RO"}>Rondônia</MenuItem>
                        <MenuItem value={"RR"}>Roraima</MenuItem>
                        <MenuItem value={"SC"}>Santa Catarina</MenuItem>
                        <MenuItem value={"SP"}>São Paulo</MenuItem>
                        <MenuItem value={"SE"}>Sergipe</MenuItem>
                        <MenuItem value={"TO"}>Tocantins</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <TextField
                      value={values.firstName}
                      error={errors.firstName !== ""}
                      helperText={errors.firstName}
                      id="firstName2"
                      label="Nome"
                      variant="outlined"
                      disabled={loading}
                      required
                      onChange={handleChange("firstName")}
                      classes={{
                        root: classes.textField,
                      }}
                    />
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <TextField
                      value={values.lastName}
                      error={errors.lastName !== ""}
                      helperText={errors.lastName}
                      id="lastName2"
                      label="Sobrenome"
                      variant="outlined"
                      disabled={loading}
                      required
                      onChange={handleChange("lastName")}
                      classes={{
                        root: classes.textField,
                      }}
                    />
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <TextField
                      label="Celular"
                      value={values.phone}
                      helperText={errors.phone}
                      error={errors.phone !== ""}
                      onChange={handleChange("phone")}
                      required
                      variant="outlined"
                      disabled={loading}
                      id="phone2"
                      InputProps={{
                        inputComponent: PhoneMask,
                      }}
                      classes={{
                        root: classes.textField,
                      }}
                    />
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <TextField
                      value={values.email}
                      error={errors.email !== ""}
                      helperText={errors.email}
                      id="email2"
                      type="email"
                      label="E-mail"
                      variant="outlined"
                      disabled={loading}
                      required
                      onChange={handleChange("email")}
                      classes={{
                        root: classes.textField,
                      }}
                    />
                  </Grid>
                  <Grid item sm={12} md={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Sexo</FormLabel>
                      <RadioGroup
                        aria-label="sex"
                        name="sex"
                        value={values.sex}
                        onChange={handleChange("sex")}
                        disabled={loading}
                      >
                        <FormControlLabel
                          value="female"
                          control={<Radio />}
                          label="Mulher"
                        />
                        <FormControlLabel
                          value="male"
                          control={<Radio />}
                          label="Homem"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} md={4}>
                    <Button
                      type="button"
                      variant="contained"
                      className={classes.buttonSubmit}
                      size="large"
                      onClick={(e) => handleCleanForm()}
                      disabled={loading}
                    >
                      Limpar
                    </Button>
                  </Grid>
                  <Grid item sm={12} md={4}>
                    <Button
                      type="button"
                      variant="contained"
                      className={classes.buttonSubmit}
                      size="large"
                      onClick={handleClose}
                      disabled={loading}
                    >
                      Fechar
                    </Button>
                  </Grid>
                  <Grid item sm={12} md={4}>
                    <Button
                      color="primary"
                      type="button"
                      variant="contained"
                      className={classes.buttonSubmit}
                      size="large"
                      disabled={loading}
                      onClick={(e) => handleSubmitNewDoctor()}
                    >
                      Adicionar
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { setMessage };

export default connect(mapStateToProps, mapDispatchToProps)(DialogNewDoctor);
