import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import api from "../../../../Services/Api";
import { setMessage } from "./../../../../Actions/messageActions";
import Loading from "./../../../../Components/Loading";
import PhoneMask from "./../../../../Components/Phone";
import DialogDeleteUser from "./DialogDeleteUser";
import DialogNewPassword from "./DialogNewPassword";
import TableAppointments from "./TableAppointments";
import TableUserAppointmentDoctors from "./TableUserAppointmentDoctors";
import TableUserLog from "./TableUserLog";

const useStyles = makeStyles((theme) => ({
  buttonSubmit: {
    width: "100%",
    color: "#fff",
  },
  textField: {
    width: "100%",
  },
  form: {
    margin: "25px auto",
  },
  roleLabel: {
    color: "#fff",
    padding: "6px 12px",
    margin: "2px",
    borderRadius: 4,
    fontSize: 14,
  },
  roleAdmin: {
    background: "#ffcdd2",
  },
  roleEmployee: {
    background: "#e1bee7",
  },
  roleClient: {
    background: "#f8bbd0",
  },
  roleDoctor: {
    background: "#bbdefb",
  },
}));

export const Index = ({
  setMessage,
  history,
  match,
  isAuthenticated,
  authRole,
}) => {
  const classes = useStyles();
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    userId: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    sex: "female",
    crm: "",
    crmState: "PR",
    medicalRecordNumber: "",
    role: "",
    flagSms: false,
    flagEmail: false,
    status: "active",
    userLogs: [],
    appointments: [],
    appointmentDoctors: [],
    roleUser: {},
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    sex: "",
    crm: "",
    crmState: "",
    medicalRecordNumber: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setErrors({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      sex: "",
      crm: "",
      crmState: "",
      medicalRecordNumber: "",
    });
    api
      .put(`/users/${match.params.id}`, {
        first_name: values.firstName,
        last_name: values.lastName,
        phone: values.phone.trim(),
        email: values.email,
        sex: values.sex,
        crm: values.crm,
        crm_state: values.crmState,
        medical_record_number: values.medicalRecordNumber,
        flag_sms: values.flagSms,
        flag_email: values.flagEmail,
        status: values.status,
      })
      .then((res) => {
        setMessage("Usuário atualizado com sucesso!", "success");
        setLoading(false);
      })
      .catch((err) => {
        const data = err.response.data;
        setMessage("Erro ao editar o usuário!", "error");
        for (let i = 0; i < data.length; i++) {
          if (data[i].field === "first_name") {
            setErrors({ ...errors, firstName: "Campo obrigatório." });
          }
          if (data[i].field === "last_name") {
            setErrors({ ...errors, lastName: "Campo obrigatório." });
          }
          if (data[i].field === "phone") {
            setErrors({ ...errors, phone: "Telefone inválido" });
          }
          if (data[i].field === "email") {
            setErrors({ ...errors, email: "E-mail inválido." });
          }
          if (data[i].field === "crm") {
            setErrors({ ...errors, crm: "Campo obrigatório." });
          }
          if (data[i].field === "crm_state") {
            setErrors({ ...errors, crmState: "Campo obrigatório." });
          }
        }
        setLoading(false);
      });
  };

  const handleToggleEmail = () => {
    setValues({ ...values, flagEmail: !values.flagEmail });
  };

  const handleToggleSms = () => {
    setValues({ ...values, flagSms: !values.flagSms });
  };

  useEffect(() => {
    if (isAuthenticated) {
      api
        .get(`/users/show/${match.params.id}`)
        .then((res) => {
          setValues({
            firstName: res.data.profile.first_name,
            lastName: res.data.profile.last_name,
            email: res.data.email,
            phone: res.data.profile.phone,
            sex: res.data.profile.sex,
            crm: res.data.profile.crm,
            crmState: res.data.profile.crm_state,
            medicalRecordNumber: res.data.medicalRecord
              ? res.data.medicalRecord.number
              : "",
            role: res.data.roleUser.role.slug,
            flagSms: res.data.profile.flag_sms,
            flagEmail: res.data.profile.flag_email,
            status: res.data.status,
            userLogs: res.data.userLogs,
            appointments: res.data.appointments,
            appointmentDoctors: res.data.appointmentDoctors,
            roleUser: res.data.roleUser,
          });
          setInitialLoading(false);
          setLoading(false);
        })
        .catch((err) => {
          setMessage("Erro ao pesquisar usuário!", "error");
          // setLoading(false);
        });
    }
  }, [isAuthenticated, match.params.id, setMessage]);

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/painel">Painel</Link>
        <Link to="/usuarios">Usuários</Link>
        <Typography>Editar Usuário</Typography>
      </Breadcrumbs>
      <Container disableGutters>
        <Typography variant="h1">Editar Usuário</Typography>
        <p>Atualize os dados do usuário.</p>
        {initialLoading && <Loading />}

        {!initialLoading && (
          <>
            <div className={classes.legendLabels}>
              <div className={classes.legendLabelsContainer}>
                {values.role === "admin" && (
                  <span className={`${classes.roleLabel} ${classes.roleAdmin}`}>
                    Administrador
                  </span>
                )}
                {values.role === "employee" && (
                  <span
                    className={`${classes.roleLabel} ${classes.roleEmployee}`}
                  >
                    Circulante
                  </span>
                )}
                {values.role === "client" && (
                  <span
                    className={`${classes.roleLabel} ${classes.roleClient}`}
                  >
                    Paciente
                  </span>
                )}
                {values.role === "doctor" && (
                  <span
                    className={`${classes.roleLabel} ${classes.roleDoctor}`}
                  >
                    Médico
                  </span>
                )}
              </div>
            </div>
            <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
              <Grid container spacing={(2, 2)}>
                {values.role === "client" && (
                  <Grid item sm={12} md={6}>
                    <TextField
                      value={values.medicalRecordNumber}
                      error={errors.medicalRecordNumber !== ""}
                      helperText={errors.medicalRecordNumber}
                      id="medicalRecordNumber"
                      label="Prontuário"
                      variant="outlined"
                      disabled={loading}
                      required
                      onChange={handleChange("medicalRecordNumber")}
                      classes={{
                        root: classes.textField,
                      }}
                    />
                  </Grid>
                )}
                {values.role === "doctor" && (
                  <>
                    <Grid item sm={12} md={6}>
                      <TextField
                        value={values.crm}
                        error={errors.crm !== ""}
                        helperText={errors.crm}
                        id="crm"
                        label="CRM"
                        variant="outlined"
                        disabled={loading}
                        required
                        onChange={handleChange("crm")}
                        classes={{
                          root: classes.textField,
                        }}
                      />
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <FormControl
                        variant="outlined"
                        className={classes.textField}
                      >
                        <InputLabel id="crm-state">CRM Estado *</InputLabel>
                        <Select
                          labelId="crm-state"
                          id="crm-state-select"
                          value={values.crmState}
                          error={errors.crmState !== ""}
                          disabled={loading}
                          onChange={handleChange("crmState")}
                          required
                        >
                          <MenuItem value={"AC"}>Acre</MenuItem>
                          <MenuItem value={"AL"}>Alagoas</MenuItem>
                          <MenuItem value={"AP"}>Amapá</MenuItem>
                          <MenuItem value={"AM"}>Amazonas</MenuItem>
                          <MenuItem value={"BA"}>Bahia</MenuItem>
                          <MenuItem value={"CE"}>Ceará</MenuItem>
                          <MenuItem value={"DF"}>Distrito Federal</MenuItem>
                          <MenuItem value={"ES"}>Espírito Santo</MenuItem>
                          <MenuItem value={"GO"}>Goiás</MenuItem>
                          <MenuItem value={"MA"}>Maranhão</MenuItem>
                          <MenuItem value={"MT"}>Mato Grosso</MenuItem>
                          <MenuItem value={"MS"}>Mato Grosso do Sul</MenuItem>
                          <MenuItem value={"MG"}>Minas Gerais</MenuItem>
                          <MenuItem value={"PA"}>Pará</MenuItem>
                          <MenuItem value={"PB"}>Paraíba</MenuItem>
                          <MenuItem value={"PR"}>Paraná</MenuItem>
                          <MenuItem value={"PE"}>Pernambuco</MenuItem>
                          <MenuItem value={"PI"}>Piauí</MenuItem>
                          <MenuItem value={"RJ"}>Rio de Janeiro</MenuItem>
                          <MenuItem value={"RN"}>Rio Grande do Norte</MenuItem>
                          <MenuItem value={"RS"}>Rio Grande do Sul</MenuItem>
                          <MenuItem value={"RO"}>Rondônia</MenuItem>
                          <MenuItem value={"RR"}>Roraima</MenuItem>
                          <MenuItem value={"SC"}>Santa Catarina</MenuItem>
                          <MenuItem value={"SP"}>São Paulo</MenuItem>
                          <MenuItem value={"SE"}>Sergipe</MenuItem>
                          <MenuItem value={"TO"}>Tocantins</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}
                <Grid item sm={12} md={6}>
                  <TextField
                    value={values.firstName}
                    error={errors.firstName !== ""}
                    helperText={errors.firstName}
                    id="firstName"
                    label="Nome"
                    variant="outlined"
                    disabled={loading}
                    required
                    onChange={handleChange("firstName")}
                    classes={{
                      root: classes.textField,
                    }}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <TextField
                    value={values.lastName}
                    error={errors.lastName !== ""}
                    helperText={errors.lastName}
                    id="lastName"
                    label="Sobrenome"
                    variant="outlined"
                    disabled={loading}
                    required
                    onChange={handleChange("lastName")}
                    classes={{
                      root: classes.textField,
                    }}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <TextField
                    label="Celular"
                    value={values.phone}
                    helperText={errors.phone}
                    error={errors.phone !== ""}
                    onChange={handleChange("phone")}
                    required
                    variant="outlined"
                    disabled={loading}
                    id="phone"
                    InputProps={{
                      inputComponent: PhoneMask,
                    }}
                    classes={{
                      root: classes.textField,
                    }}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <TextField
                    value={values.email}
                    error={errors.email !== ""}
                    helperText={errors.email}
                    id="email"
                    type="email"
                    label="E-mail"
                    variant="outlined"
                    disabled={loading}
                    required
                    onChange={handleChange("email")}
                    classes={{
                      root: classes.textField,
                    }}
                  />
                </Grid>
                <Grid item sm={12} md={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Sexo</FormLabel>
                    <RadioGroup
                      aria-label="sex"
                      name="sex"
                      value={values.sex}
                      onChange={handleChange("sex")}
                      disabled={loading}
                    >
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label="Mulher"
                      />
                      <FormControlLabel
                        value="male"
                        control={<Radio />}
                        label="Homem"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          size="medium"
                          checked={values.flagEmail}
                          onChange={(e) => handleToggleEmail("flagEmail")}
                          disabled={loading}
                        />
                      }
                      label="Receber notificações por e-mail."
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          size="medium"
                          checked={values.flagSms}
                          onChange={(e) => handleToggleSms("flagSms")}
                          disabled={loading}
                        />
                      }
                      label="Receber notificações por SMS."
                    />
                  </FormGroup>
                </Grid>
                {values.role === "client" && (
                  <Grid item sm={12} md={12}>
                    <TableAppointments
                      history={history}
                      appointments={values.appointments}
                    />
                  </Grid>
                )}
                {values.role === "doctor" && (
                  <Grid item sm={12} md={12}>
                    <TableUserAppointmentDoctors
                      history={history}
                      appointmentDoctors={values.appointmentDoctors}
                    />
                  </Grid>
                )}
                {authRole === "admin" && (
                  <Grid item sm={12} md={12}>
                    <TableUserLog logs={values.userLogs} />
                  </Grid>
                )}
                <Grid item sm={12} md={12}>
                  <FormControl variant="outlined" className={classes.textField}>
                    <InputLabel id="status">Status *</InputLabel>
                    <Select
                      labelId="status"
                      id="status-select"
                      value={values.status}
                      disabled={loading}
                      onChange={handleChange("status")}
                      required
                    >
                      <MenuItem value={"active"}>Ativo</MenuItem>
                      <MenuItem value={"blocked"}>Bloqueado</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {authRole === "admin" && (
                  <>
                    <Grid item sm={6} md={6}>
                      <DialogNewPassword
                        history={history}
                        userId={match.params.id}
                      />
                    </Grid>
                    <Grid item sm={6} md={6}>
                      <DialogDeleteUser
                        history={history}
                        userId={match.params.id}
                      />
                    </Grid>
                    <Grid item sm={6} md={6}>
                      <Button
                        type="button"
                        variant="contained"
                        className={classes.buttonSubmit}
                        size="large"
                        disabled={loading}
                        onClick={(e) => {
                          history.goBack();
                        }}
                      >
                        Voltar
                      </Button>
                    </Grid>
                    <Grid item sm={6} md={6}>
                      <Button
                        color="primary"
                        type="submit"
                        variant="contained"
                        className={classes.buttonSubmit}
                        size="large"
                        disabled={loading}
                      >
                        Editar Usuário
                      </Button>
                    </Grid>
                  </>
                )}
                {authRole === "employee" && (
                  <>
                    <Grid item sm={6} md={6}>
                      <DialogNewPassword
                        history={history}
                        userId={match.params.id}
                      />
                    </Grid>
                    <Grid item sm={6} md={6}>
                      <Button
                        type="button"
                        variant="contained"
                        className={classes.buttonSubmit}
                        size="large"
                        disabled={loading}
                        onClick={(e) => {
                          history.goBack();
                        }}
                      >
                        Voltar
                      </Button>
                    </Grid>
                    <Grid item sm={12} md={12}>
                      <Button
                        color="primary"
                        type="submit"
                        variant="contained"
                        className={classes.buttonSubmit}
                        size="large"
                        disabled={loading}
                      >
                        Editar Usuário
                      </Button>
                    </Grid>
                  </>
                )}
                {authRole !== "admin" && authRole !== "employee" && (
                  <>
                    <Grid item sm={12} md={6}>
                      <Button
                        type="button"
                        variant="contained"
                        className={classes.buttonSubmit}
                        size="large"
                        disabled={loading}
                        onClick={(e) => {
                          history.goBack();
                        }}
                      >
                        Voltar
                      </Button>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <Button
                        color="primary"
                        type="submit"
                        variant="contained"
                        className={classes.buttonSubmit}
                        size="large"
                        disabled={loading}
                      >
                        Editar Usuário
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          </>
        )}
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  authRole: state.auth.role.slug,
});

const mapDispatchToProps = { setMessage };

export default connect(mapStateToProps, mapDispatchToProps)(Index);
