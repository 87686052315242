import isEmpty from "../Helpers/isEmpty";

import {
  SET_CURRENT_USER,
  SET_USER_PROFILE,
  CLEAR_CURRENT_USER,
} from "../Actions/types";

const initialState = {
  isAuthenticated: false,
  user: {},
  role: {},
  token: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload.user,
        role: action.payload.role,
        token: action.payload.token,
      };
    case SET_USER_PROFILE:
      return {
        ...state,
        user: action.payload,
      };
    case CLEAR_CURRENT_USER:
      return initialState;
    default:
      return state;
  }
}
