import { Breadcrumbs, Container, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import isEmpty from "../../../../Helpers/isEmpty";
import api from "../../../../Services/Api";
import { setMessage } from "./../../../../Actions/messageActions";
import Loading from "./../../../../Components/Loading";
import TableMedicalRecordDownloads from "./TableMedicalRecordDownloads";

export const Index = ({ setMessage, user, history, isAuthenticated }) => {
  const [loading, setLoading] = useState(false);
  const [medicalRecordDownloads, setMedicalRecordDownloads] = useState([]);
  const [params, setParams] = useState({
    order: "desc",
    orderBy: "created_at",
    page: 0,
    rowsPerPage: 50,
    total: 0,
    medicalRecordNumber: "",
    doctors: "",
    status: "",
  });

  const getMedicalRecordDownloads = ({
    pOrder,
    pOrderBy,
    pPage,
    pRowsPerPage,
    pMedicalRecordNumber,
    pDoctors,
    pStatus,
  }) => {
    setLoading(true);
    api
      .get("medical_record_downloads", {
        params: {
          order: pOrder,
          orderBy: pOrderBy,
          page: pPage,
          rowsPerPage: pRowsPerPage,
          medical_record_number: pMedicalRecordNumber,
          doctors: pDoctors,
          status: pStatus,
        },
      })
      .then((res) => {
        if (!isEmpty(res.data)) {
          setMedicalRecordDownloads(res.data);
          setParams({
            ...params,
            total: parseInt(res.data.total),
            page: res.data.page - 1,
            rowsPerPage: res.data.perPage,
          });
        } else {
          setMessage("Erro ao fazer a pesquisa, tente novamente!", "error");
        }
        setLoading(false);
      })
      .catch((err) => {
        setMessage(
          "Você não tem permissão para ver a fila de arquivos!",
          "error"
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    let mounted = true;
    setLoading(true);
    if (isAuthenticated) {
      const loadData = async () => {
        await api
          .get("medical_record_downloads", { params })
          .then((res) => {
            if (mounted) {
              setMedicalRecordDownloads(res.data);
              setLoading(false);
              setParams({
                ...params,
                total: parseInt(res.data.total),
                page: res.data.page - 1,
                rowsPerPage: res.data.perPage,
              });
            }
          })
          .catch((err) => {
            setMessage(
              "Você não tem permissão para ver a fila de arquivos!",
              "error"
            );
            // history.push("/painel");
          });
      };
      loadData();
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/painel">Painel</Link>
        <Link to="/exames">Exames</Link>
        <Typography>Fila de Arquivos</Typography>
      </Breadcrumbs>
      <Container disableGutters>
        <Typography variant="h1">Fila de Arquivos</Typography>
        <p>Lista a fila de arquivos do sistema.</p>
        {loading && <Loading />}
        {!loading && (
          <TableMedicalRecordDownloads
            medicalRecordDownloads={medicalRecordDownloads}
            getMedicalRecordDownloads={getMedicalRecordDownloads}
            params={params}
            setParams={setParams}
          />
        )}
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = { setMessage };

export default connect(mapStateToProps, mapDispatchToProps)(Index);
