// import Error from "./Containers/Page/Error";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { getCurrentUser } from "./Actions/authActions";
import PrivateRoute from "./Components/PrivateRoute";
import Appointments from "./Containers/Admin/Appointment/Index/Index";
import AppointmentUpdate from "./Containers/Admin/Appointment/Update/Index";
import Dashboard from "./Containers/Admin/Dashboard/Index";
import MedicalRecordDownloadCreate from "./Containers/Admin/MedicalRecordDownload/Create/Index";
import MedicalRecordDownloads from "./Containers/Admin/MedicalRecordDownload/Index/Index";
import Profile from "./Containers/Admin/Profile/Index";
import CreateAdmin from "./Containers/Admin/User/Create/CreateAdmin";
import CreateDoctor from "./Containers/Admin/User/Create/CreateDoctor";
import CreateMod from "./Containers/Admin/User/Create/CreateMod";
import Users from "./Containers/Admin/User/Index/Index";
import UserUpdate from "./Containers/Admin/User/Update/Index";
import Help from "./Containers/Admin/Help/Index";
import Login from "./Containers/Auth/Login/Index";
import Logout from "./Containers/Auth/Logout/Index";
import Recovery from "./Containers/Auth/Recovery/Index";
import NewPassword from "./Containers/Auth/Recovery/NewPassword";
import Error from "./Containers/Error/Index";

const Routes = ({ getCurrentUser, isAuthenticated }) => {
  useEffect(() => {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    if (token !== "" && !isAuthenticated) {
      getCurrentUser(token, refreshToken);
    }
  }, [getCurrentUser, isAuthenticated]);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Login} exact />
        <Route path="/recuperar-senha" component={Recovery} exact />
        <Route
          path="/nova-senha/:recovery_code"
          component={NewPassword}
          exact
        />
        <PrivateRoute path="/painel" component={Dashboard} exact />
        <PrivateRoute path="/perfil" component={Profile} exact />
        <PrivateRoute
          path="/exame"
          component={MedicalRecordDownloadCreate}
          exact
        />
        <PrivateRoute path="/exames" component={Appointments} exact />
        <PrivateRoute path="/exames/:id" component={AppointmentUpdate} exact />
        <PrivateRoute path="/medico" component={CreateDoctor} exact />
        <PrivateRoute path="/circulante" component={CreateMod} exact />
        <PrivateRoute path="/administrador" component={CreateAdmin} exact />
        <PrivateRoute
          path="/arquivos"
          component={MedicalRecordDownloads}
          exact
        />
        <PrivateRoute path="/usuarios" component={Users} exact />
        <PrivateRoute path="/usuarios/:id" component={UserUpdate} exact />
        <PrivateRoute path="/ajuda" component={Help} exact />
        <PrivateRoute path="/sair" component={Logout} exact />
        <Route component={Error} />
      </Switch>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = { getCurrentUser };

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
