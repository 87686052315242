import React from "react";
import { connect } from "react-redux";
import {
  Breadcrumbs,
  Typography,
  Container,
  Grid,
  Button,
} from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import isEmpty from "./../../../Helpers/isEmpty";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
// import GeneralChart from "./Charts/GeneralChart";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  button: {
    color: "#fff",
    width: 110,
  },
  buttonFeatured: {
    width: "100%",
    height: 130,
    margin: "6px auto 7px",
    fontSize: 20,
    fontWeight: 100,
    color: "#fff",
  },
  buttonContainer: {
    width: "100%",
    textAlign: "right",
    top: -40,
    position: "relative",
    height: 0,
  },
  table: {
    minWidth: "100%",
  },
  tableContainer: {
    marginTop: 5,
  },
  labelTable: {
    fontSize: 16,
    fontWeight: 100,
    color: "#212121",
  },
  textTable: {
    fontSize: 16,
    fontWeight: 400,
    color: "#212121",
  },
  link: {
    color: "#fff",
    textDecoration: "none",
  },
  no: {
    width: 10,
    height: 10,
    borderRadius: "50%",
    background: "red",
    float: "right",
  },
  yes: {
    width: 10,
    height: 10,
    borderRadius: "50%",
    background: "green",
    float: "right",
  },
});

export const Index = ({ user, role }) => {
  const classes = useStyles();

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography>Painel</Typography>
      </Breadcrumbs>
      <Container disableGutters>
        {!isEmpty(user) && (
          <Grid container spacing={(2, 3)}>
            {(role.slug === "admin" || role.slug === "employee") && (
              <Grid item sm={12} md={5}>
                <Typography variant="h2">Acesso Rápido</Typography>
                <Link className={`${classes.link}`} to="/exame">
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    className={classes.buttonFeatured}
                  >
                    Adicionar Exame
                  </Button>
                </Link>
                <Link className={`${classes.link}`} to="/medico">
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.buttonFeatured}
                  >
                    Adicionar Médico
                  </Button>
                </Link>
              </Grid>
            )}
            <Grid
              item
              sm={12}
              md={role === "admin" || role === "employee" ? 12 : 7}
            >
              <Typography variant="h2">Perfil</Typography>
              <>
                <div className={classes.buttonContainer}>
                  <Link className={classes.link} to="/perfil">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.button}
                    >
                      Atualizar
                    </Button>
                  </Link>
                </div>
                <TableContainer
                  component={Paper}
                  className={classes.tableContainer}
                  elevation={1}
                >
                  <Table className={classes.table} size="medium">
                    <TableBody>
                      {role.slug === "client" && (
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.labelTable}
                          >
                            Prontuário
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.textTable}
                          >
                            {user.medicalRecord.number}
                          </TableCell>
                        </TableRow>
                      )}
                      {role.slug === "doctor" && (
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.labelTable}
                          >
                            CRM
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.textTable}
                          >
                            {user.profile.crm} {user.profile.crm_state}
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.labelTable}
                        >
                          Nome
                        </TableCell>
                        <TableCell align="right" className={classes.textTable}>
                          {user.profile.first_name} {user.profile.last_name}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.labelTable}
                        >
                          E-mail
                        </TableCell>
                        <TableCell align="right" className={classes.textTable}>
                          {user.email}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.labelTable}
                        >
                          Telefone
                        </TableCell>
                        <TableCell align="right" className={classes.textTable}>
                          {user.profile.phone}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.labelTable}
                        >
                          Alertas por E-mail
                        </TableCell>
                        <TableCell align="right" className={classes.textTable}>
                          {user.profile.flag_email ? (
                            <div className={classes.yes} />
                          ) : (
                            <div className={classes.no} />
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.labelTable}
                        >
                          Alertas por SMS
                        </TableCell>
                        <TableCell align="right" className={classes.textTable}>
                          {user.profile.flag_sms ? (
                            <div className={classes.yes} />
                          ) : (
                            <div className={classes.no} />
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            </Grid>
          </Grid>
        )}
        {/* {(role.slug === "admin" || role.slug === "employee") && (
          <GeneralChart />
        )} */}
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  role: state.auth.role,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
