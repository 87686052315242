import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import React from "react";

const useStyles = makeStyles({
  table: {
    minWidth: "100%",
  },
  statusBlocked: {
    height: 10,
    width: 10,
    background: "red",
    borderRadius: "50%",
    margin: "auto",
  },
  statusActive: {
    height: 10,
    width: 10,
    background: "green",
    borderRadius: "50%",
    margin: "auto",
  },
  statusLabel: {
    padding: "4px 10px",
    margin: "2px",
    borderRadius: 4,
    fontSize: 12,
    color: "#212121",
  },
  row: {
    cursor: "pointer",
  },
});

export default function TableUserAppointmentDoctors({
  appointmentDoctors,
  history,
}) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Data do Exame</TableCell>
            <TableCell>Paciente</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {appointmentDoctors.map((row, index) => {
            let statusClass = "";
            if (row.appointment.status === "active") {
              statusClass = classes.statusActive;
            }
            if (row.appointment.status === "blocked") {
              statusClass = classes.statusBlocked;
            }
            return (
              <TableRow
                hover
                key={index}
                onClick={(e) => history.push(`/exames/${row.appointment.id}`)}
                className={classes.row}
              >
                <TableCell>{moment(row.date).format("DD/MM/YYYY")}</TableCell>
                <TableCell>{`${row.appointment.user.profile.first_name} ${row.appointment.user.profile.last_name}`}</TableCell>
                <TableCell>
                  <div className={statusClass}></div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
