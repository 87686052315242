import DateFnsUtils from "@date-io/date-fns";
import {
  Breadcrumbs,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
// choose your lib
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import brLocale from "date-fns/locale/pt-BR";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import api from "../../../Services/Api";
import { setUserProfile } from "./../../../Actions/authActions";
import { setMessage } from "./../../../Actions/messageActions";
import PhoneMask from "./../../../Components/Phone";
import isEmpty from "./../../../Helpers/isEmpty";

const useStyles = makeStyles((theme) => ({
  buttonSubmit: {
    width: "250px",
    margin: "10px auto",
    color: "#fff",
  },
  textField: {
    width: "100%",
  },
  form: {
    margin: "25px auto",
  },
}));

export const Index = ({ role, user, setMessage, setUserProfile }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    medicalRecordNumber: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    birthday: null,
    sex: "female",
    showPassword: false,
    showConfirmPassword: false,
    password: "",
    confirmPassword: "",
    flagSms: false,
    flagEmail: false,
    crm: "",
    crmState: "PR",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    birthday: "",
    sex: "",
    password: "",
    flagEmail: "",
    flagSms: "",
    crm: "",
    crmState: "",
  });

  useEffect(() => {
    if (!isEmpty(user)) {
      setValues({
        ...values,
        firstName: user.profile.first_name,
        lastName: user.profile.last_name,
        phone: user.profile.phone,
        birthday: user.profile.birthday,
        email: user.email,
        sex: user.profile.sex,
        flagEmail: user.profile.flag_email,
        flagSms: user.profile.flag_sms,
        crm: user.profile.crm,
        crmState: user.profile.crm_state,
      });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const handleChangeDate = (date) => {
    setValues({ ...values, birthday: date });
  };

  const handleToggleEmail = () => {
    setValues({ ...values, flagEmail: !values.flagEmail });
  };

  const handleToggleSms = () => {
    setValues({ ...values, flagSms: !values.flagSms });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setErrors({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      birthday: "",
      sex: "",
      password: "",
      flagEmail: "",
      flagSms: "",
      crm: "",
      crmState: "",
    });
    if (values.password !== values.confirmPassword) {
      setErrors({
        ...errors,
        password: "A senha e a confirmação de senha não são iguais.",
      });
      setLoading(false);
    } else {
      api
        .put(`/users/${user.id}`, {
          first_name: values.firstName,
          last_name: values.lastName,
          phone: values.phone.trim(),
          birthday: values.birthday,
          email: values.email,
          password: values.password,
          sex: values.sex,
          flag_email: values.flagEmail,
          flag_sms: values.flagSms,
          crm: values.crm,
          crm_state: values.crmState,
        })
        .then((res) => {
          setMessage("Perfil atualizado com sucesso!", "success");
          setUserProfile(res.data);
          setLoading(false);
        })
        .catch((err) => {
          const data = err.response.data;
          setMessage("Erro ao atualizar o seu perfil!", "error");
          for (let i = 0; i < data.length; i++) {
            if (data[i].field === "first_name") {
              setErrors({ ...errors, fistName: "Campo obrigatório." });
            }
            if (data[i].field === "last_name") {
              setErrors({ ...errors, lastName: "Campo obrigatório." });
            }
            if (data[i].field === "phone") {
              setErrors({ ...errors, phone: "Telefone inválido" });
            }
            if (data[i].field === "birthday") {
              setErrors({ ...errors, birthday: "Formato inválido." });
            }
            if (data[i].field === "email") {
              setErrors({ ...errors, email: "E-mail inválido." });
            }
            if (data[i].field === "password") {
              setErrors({ ...errors, password: "Campo obrigatório." });
            }
            if (data[i].field === "crm") {
              setErrors({ ...errors, crm: "Campo obrigatório." });
            }
            if (data[i].field === "crm_state") {
              setErrors({ ...errors, crmState: "Campo obrigatório." });
            }
          }
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/painel">Painel</Link>
        <Typography>Perfil</Typography>
      </Breadcrumbs>
      <Container disableGutters>
        <Typography variant="h1">Perfil</Typography>
        <p>Lembre-se de manter suas informações atualizadas.</p>
        <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
          <Grid container spacing={(2, 3)}>
            {role === "doctor" && (
              <>
                <Grid item sm={12} md={6}>
                  <TextField
                    value={values.crm}
                    error={errors.crm !== ""}
                    helpertext={errors.crm}
                    id="crm"
                    label="CRM"
                    variant="outlined"
                    disabled={loading}
                    required
                    onChange={handleChange("crm")}
                    classes={{
                      root: classes.textField,
                    }}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <FormControl variant="outlined" className={classes.textField}>
                    <InputLabel id="crm-state">CRM Estado *</InputLabel>
                    <Select
                      labelId="crm-state"
                      id="crm-state-select"
                      value={values.crmState}
                      error={errors.crmState !== ""}
                      helpertext={errors.crmState}
                      disabled={loading}
                      onChange={handleChange("crmState")}
                      required
                    >
                      <MenuItem value={"AC"}>Acre</MenuItem>
                      <MenuItem value={"AL"}>Alagoas</MenuItem>
                      <MenuItem value={"AP"}>Amapá</MenuItem>
                      <MenuItem value={"AM"}>Amazonas</MenuItem>
                      <MenuItem value={"BA"}>Bahia</MenuItem>
                      <MenuItem value={"CE"}>Ceará</MenuItem>
                      <MenuItem value={"DF"}>Distrito Federal</MenuItem>
                      <MenuItem value={"ES"}>Espírito Santo</MenuItem>
                      <MenuItem value={"GO"}>Goiás</MenuItem>
                      <MenuItem value={"MA"}>Maranhão</MenuItem>
                      <MenuItem value={"MT"}>Mato Grosso</MenuItem>
                      <MenuItem value={"MS"}>Mato Grosso do Sul</MenuItem>
                      <MenuItem value={"MG"}>Minas Gerais</MenuItem>
                      <MenuItem value={"PA"}>Pará</MenuItem>
                      <MenuItem value={"PB"}>Paraíba</MenuItem>
                      <MenuItem value={"PR"}>Paraná</MenuItem>
                      <MenuItem value={"PE"}>Pernambuco</MenuItem>
                      <MenuItem value={"PI"}>Piauí</MenuItem>
                      <MenuItem value={"RJ"}>Rio de Janeiro</MenuItem>
                      <MenuItem value={"RN"}>Rio Grande do Norte</MenuItem>
                      <MenuItem value={"RS"}>Rio Grande do Sul</MenuItem>
                      <MenuItem value={"RO"}>Rondônia</MenuItem>
                      <MenuItem value={"RR"}>Roraima</MenuItem>
                      <MenuItem value={"SC"}>Santa Catarina</MenuItem>
                      <MenuItem value={"SP"}>São Paulo</MenuItem>
                      <MenuItem value={"SE"}>Sergipe</MenuItem>
                      <MenuItem value={"TO"}>Tocantins</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item sm={12} md={6}>
              <TextField
                value={values.firstName}
                error={errors.firstName !== ""}
                helpertext={errors.firstName}
                id="firstName"
                label="Nome"
                variant="outlined"
                disabled={loading}
                required
                onChange={handleChange("firstName")}
                classes={{
                  root: classes.textField,
                }}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                value={values.lastName}
                error={errors.lastName !== ""}
                helpertext={errors.lastName}
                id="lastName"
                label="Sobrenome"
                variant="outlined"
                disabled={loading}
                required
                onChange={handleChange("lastName")}
                classes={{
                  root: classes.textField,
                }}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                label="Celular"
                value={values.phone}
                helpertext={errors.phone}
                error={errors.phone !== ""}
                onChange={handleChange("phone")}
                required
                variant="outlined"
                disabled={loading}
                id="phone"
                InputProps={{
                  inputComponent: PhoneMask,
                }}
                classes={{
                  root: classes.textField,
                }}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
                <KeyboardDatePicker
                  label="Aniversário"
                  inputVariant="outlined"
                  error={errors.birthday !== ""}
                  helpertext={errors.birthday}
                  clearable
                  required
                  disabled={loading}
                  value={values.birthday}
                  className={classes.textField}
                  onChange={(date) => handleChangeDate(date)}
                  format="dd/MM/yyyy"
                  clearLabel="Limpar"
                  cancelLabel="Cancelar"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item sm={12} md={12}>
              <TextField
                value={values.email}
                error={errors.email !== ""}
                helpertext={errors.email}
                id="email"
                type="email"
                label="E-mail"
                variant="outlined"
                disabled={loading}
                required
                onChange={handleChange("email")}
                classes={{
                  root: classes.textField,
                }}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                value={values.password}
                className={classes.textField}
                error={errors.password !== ""}
                helpertext={errors.password}
                id="password"
                label="Senha"
                variant="outlined"
                type={values.showPassword ? "text" : "password"}
                onChange={handleChange("password")}
                disabled={loading}
                classes={{
                  root: classes.textField,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                value={values.confirmPassword}
                className={classes.textField}
                error={errors.password !== ""}
                id="confirmPassword"
                label="Confirmar Senha"
                variant="outlined"
                type={values.showConfirmPassword ? "text" : "password"}
                onChange={handleChange("confirmPassword")}
                disabled={loading}
                classes={{
                  root: classes.textField,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                      >
                        {values.showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item sm={12} md={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Sexo</FormLabel>
                <RadioGroup
                  aria-label="sex"
                  name="sex"
                  value={values.sex}
                  onChange={handleChange("sex")}
                  disabled={loading}
                >
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Mulher"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Homem"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item sm={12} md={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      size="medium"
                      checked={values.flagEmail}
                      onChange={(e) => handleToggleEmail("flagEmail")}
                      disabled={loading}
                    />
                  }
                  label="Receber notificações por e-mail."
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="medium"
                      checked={values.flagSms}
                      onChange={(e) => handleToggleSms("flagSms")}
                      disabled={loading}
                    />
                  }
                  label="Receber notificações por SMS."
                />
              </FormGroup>
            </Grid>
            <Grid item sm={12} md={12} style={{ textAlign: "center" }}>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                className={classes.buttonSubmit}
                size="large"
                disabled={loading}
              >
                Atualizar Perfil
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  role: state.auth.role.slug,
  user: state.auth.user,
});

const mapDispatchToProps = { setMessage, setUserProfile };

export default connect(mapStateToProps, mapDispatchToProps)(Index);
