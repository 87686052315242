import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import { connect } from "react-redux";

import { setMessage } from "../../../../Actions/messageActions";
import api from "../../../../Services/Api";

function DialogDeleteMedicalRecordDownload({ data, handleRefresh }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // handleRemoveDoctor(row);
  };

  const handleDelete = () => {
    api
      .delete(`/medical_record_downloads/${data.id}`)
      .then((res) => {
        setOpen(false);
        setMessage("Médico solicitante removido com sucesso!", "info");
        handleRefresh();
      })
      .catch((err) => {
        setMessage("Erro ao atualizar o seu perfil!", "error");
      });
  };

  return (
    <>
      <Button
        style={{ color: "#ef5350" }}
        type="button"
        variant="text"
        onClick={(e) => handleClickOpen()}
      >
        Deletar da Fila de Arquivos
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">
          {"Deletar da Fila de Arquivos"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Você realmente deseja deletar esse prontuário da fila de arquivos?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => handleClose()} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Deletar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { setMessage };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DialogDeleteMedicalRecordDownload);
