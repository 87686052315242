import { CLEAR_MESSAGE, SET_MESSAGE } from "../Actions/types";

const initialState = {
  message: "",
  type: "",
  display: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_MESSAGE:
      return {
        message: action.payload.message,
        type: action.payload.type,
        display: true,
      };
    case CLEAR_MESSAGE:
      return initialState;
    default:
      return state;
  }
}
