import React, { useEffect } from "react";
import { connect } from "react-redux";

import { logout } from "../../../Actions/authActions";
import { AppBar } from "@material-ui/core";

export const Index = ({ logout, history }) => {
  useEffect(() => {
    logout();
    history.push("/");
  }, [history, logout]);
  return <AppBar>Logout</AppBar>;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
