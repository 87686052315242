import React from "react";
import { connect } from "react-redux";
import { Breadcrumbs, Typography, Container, Grid } from "@material-ui/core";

import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  image: {
    width: "100%",
    margin: "15px auto",
  },
});

export const Index = () => {
  const classes = useStyles();

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/painel">Painel</Link>
        <Typography>Ajuda</Typography>
      </Breadcrumbs>
      <Container disableGutters>
        <Grid>
          <Typography variant="h1" style={{ marginBottom: 20 }}>
            Ajuda
          </Typography>
          <Typography variant="h2">Como cadastrar um novo exame?</Typography>
          <p>
            Ao final de cada exame realizado (mesmo se a paciente não gravou),
            clicar em "ADICIONAR EXAME” ou “NOVO EXAME”.
          </p>
          <img className={classes.image} src="img/help/1.png" alt="Passo 1" />
          <p>
            Digitar o prontuário da Paciente e clicar em “BUSCAR PRONTUÁRIO”
          </p>
          <img className={classes.image} src="img/help/2.png" alt="Passo 2" />
          <p>
            Pode haver 2 situações: a paciente nunca foi cadastrada ou a
            paciente já tem cadastro na nova plataforma.
          </p>
          <Typography variant="h3">SITUAÇÃO 1:</Typography>
          <p>
            Nunca foi cadastrada - o próximo passo é preencher os dados com
            cuidado. ATENÇÃO: os dados e principalmente o e-mail devem ser
            preenchidos corretamente. Sem isso, a paciente não poderá acessar a
            plataforma.
          </p>
          <p style={{ color: "red" }}>
            SEMPRE confirmar o e-mail com a paciente.
          </p>
          <img className={classes.image} src="img/help/3.png" alt="Passo 3" />
          <p>
            Após confirmar os dados, clicar em “Adicionar na Fila de Arquivos”.
          </p>
          <img className={classes.image} src="img/help/4.png" alt="Passo 4" />
          <p>Pronto!!!</p>
          <p>
            Comunicar à paciente que o resultado e, se for o caso, o vídeo,
            estarão na plataforma em até 24 horas úteis.
          </p>
          <Typography variant="h3">SITUAÇÃO 2:</Typography>
          <p>
            A paciente já foi cadastrada na plataforma. Os dados já aparecerão
            preenchidos automaticamente. Confirme os dados com cuidado e
            atualize se necessário.
          </p>
          <p>
            ATENÇÃO: todos os dados e principalmente o e-mail devem ser
            preenchidos corretamente. Sem isso, a paciente não poderá acessar a
            plataforma.
          </p>
          <img className={classes.image} src="img/help/5.png" alt="Passo 5" />
          <p>
            Após confirmar os dados, clicar em “Adicionar na Fila de Arquivos”.
            Pronto!!! Comunicar à paciente que o resultado e, se for o caso, o
            vídeo, estarão na plataforma em até 24 horas úteis.
          </p>
          <p>
            Nos casos em que há necessidade de postar com urgência, ou quando
            houve erro na transferência e queremos priorizar o envio, clicar no
            quadradinho e selecionar a opção “MARCAR COMO URGENTE” e então
            clicar em “ADICIONAR NA FILA DE ARQUIVOS”.
          </p>
          <img className={classes.image} src="img/help/6.png" alt="Passo 6" />
        </Grid>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
