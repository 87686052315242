import { Button, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import { connect } from "react-redux";

import { setMessage } from "../../../../Actions/messageActions";
import DialogNewDoctor from "./DialogNewDoctor";
import DialogSearchDoctor from "./DialogSearchDoctor";

const headCells = [
  {
    id: "profiles.crm",
    disablePadding: false,
    label: "CRM",
  },
  {
    id: "profiles.crm_state",
    disablePadding: false,
    label: "UF",
  },
  {
    id: "profiles.first_name",
    disablePadding: false,
    label: "Nome",
  },
  {
    id: "profiles.last_name",
    disablePadding: false,
    label: "Sobrenome",
  },
  {
    id: "Remover",
    disablePadding: false,
    label: "Remover",
    align: "right",
  },
];

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align ? headCell.align : "left"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  legendLabels: {
    margin: "0px 0px 20px",
    "& p": {
      fontSize: 10,
      color: "#ccc",
      margin: "20px 0px 2px 3px",
    },
  },
  roleLabel: {
    padding: "4px 10px",
    margin: "2px",
    borderRadius: 4,
    fontSize: 12,
    color: "#212121",
  },
  roleAdmin: {
    background: "#ffcdd2",
  },
  roleEmployee: {
    background: "#e1bee7",
  },
  roleClient: {
    background: "#f8bbd0",
  },
  roleDoctor: {
    background: "#bbdefb",
  },
  grow: {
    flexGrow: 1,
  },
  textField: {
    width: "100%",
  },
  buttonSubmit: {
    color: "#fff",
    width: "100%",
  },
  buttonClean: {
    width: "100%",
    color: "#212121",
    fontWeight: 300,
  },
  form: {
    margin: "30px auto",
  },
}));

function EnhancedTable({
  doctors,
  roles,
  setDoctors,
  params,
  setMessage,
  setParams,
}) {
  const classes = useStyles();

  const handleRemoveDoctor = (user) => {
    let users = JSON.parse(JSON.stringify(doctors));
    for (let i = 0; i < users.length; i++) {
      if (user.id === users[i].id) {
        users.splice(i);
        setMessage("Médico solicitante removido com sucesso!", "info");
      }
    }
    setDoctors(users);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar>
          <Typography variant="h3">Vincular Médicos Solicitantes</Typography>
          <div className={classes.grow}></div>
          <DialogSearchDoctor doctors={doctors} setDoctors={setDoctors} />
          <DialogNewDoctor doctors={doctors} setDoctors={setDoctors} />
        </Toolbar>
        {doctors.length === 0 && (
          <TableContainer>
            <div
              style={{
                textAlign: "center",
                margin: "30px auto 50px",
                color: "#ccc",
              }}
            >
              Nenhum médico vinculado neste prontuário.
            </div>
          </TableContainer>
        )}
        {doctors.length > 0 && (
          <TableContainer>
            <Table className={classes.table}>
              <EnhancedTableHead classes={classes} />
              <TableBody>
                {doctors.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      key={row.id}
                      className={`${classes.tableRow}`}
                    >
                      <TableCell>{row.profile.crm}</TableCell>
                      <TableCell>{row.profile.crm_state}</TableCell>
                      <TableCell>{row.profile.first_name}</TableCell>
                      <TableCell>{row.profile.last_name}</TableCell>
                      <TableCell align="right">
                        <Button
                          type="button"
                          variant="text"
                          onClick={(e) => handleRemoveDoctor(row)}
                        >
                          <DeleteIcon
                            size="small"
                            style={{ color: "#ef5350" }}
                          />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { setMessage };

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedTable);
