import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setMessage } from "../../Actions/messageActions";

export const Index = ({ isAuthenticated, history, setMessage }) => {
  useEffect(() => {
    if (isAuthenticated) {
      setMessage("Essa página não existe.", "error");
      history.push("/painel");
    } else {
      setMessage("Essa página não existe.", "error");
      history.push("/");
    }
  }, [history, isAuthenticated, setMessage]);
  return <></>;
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = { setMessage };

export default connect(mapStateToProps, mapDispatchToProps)(Index);
