import jwt_decode from "jwt-decode";

import setAuthToken from "../Helpers/setAuthToken";
import api from "../Services/Api";
import history from "./../Helpers/history";
import {
  CLEAR_CURRENT_USER,
  SET_CURRENT_USER,
  SET_MESSAGE,
  SET_USER_PROFILE,
} from "./types";

export const getCurrentUser = (token, refreshToken) => (dispatch) => {
  setAuthToken(token);
  api
    .get("/auth/me", { params: { refreshToken } })
    .then((res) => {
      const expirationData = jwt_decode(res.data.token.token);
      res.data.token.iat = expirationData.iat;
      localStorage.setItem("token", res.data.token.token);
      localStorage.setItem("refreshToken", res.data.token.refreshToken);
      setAuthToken(res.data.token.token);
      dispatch({
        type: SET_CURRENT_USER,
        payload: res.data,
      });
    })
    .catch((err) => {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      setAuthToken(false);
      dispatch({
        type: CLEAR_CURRENT_USER,
        payload: {},
      });
      history.push("/");
    });
};

export const setUserProfile = (decoded) => (dispath) => {
  dispath({
    type: SET_USER_PROFILE,
    payload: decoded,
  });
};

export const setCurrentUser = (decoded) => (dispath) => {
  const expirationData = jwt_decode(decoded.token.token);
  decoded.token.iat = expirationData.iat;
  localStorage.setItem("token", decoded.token.token);
  localStorage.setItem("refreshToken", decoded.token.refreshToken);
  setAuthToken(decoded.token.token);
  dispath({
    type: SET_CURRENT_USER,
    payload: decoded,
  });
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  setAuthToken(false);
  dispatch({
    type: CLEAR_CURRENT_USER,
    payload: {},
  });
  dispatch({
    type: SET_MESSAGE,
    payload: {
      message: "Você saiu com sucesso!",
      type: "warning",
    },
  });
  history.push("/");
};
