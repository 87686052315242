import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import React, { useEffect, useState } from "react";

import isEmpty from "./../../../../Helpers/isEmpty";

const headCells = [
  {
    id: "date",
    disablePadding: false,
    label: "Data do Exame",
  },
  {
    id: "profiles.first_name",
    disablePadding: false,
    label: "Nome",
  },
  {
    id: "profiles.last_name",
    disablePadding: false,
    label: "Sobrenome",
  },
];

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  legendLabels: {
    margin: "0px 0px 20px",
    "& p": {
      fontSize: 10,
      color: "#ccc",
      margin: "20px 0px 2px 3px",
    },
  },
  roleLabel: {
    padding: "4px 10px",
    margin: "2px",
    borderRadius: 4,
    fontSize: 12,
    color: "#212121",
  },
  tableRow: {
    cursor: "pointer",
  },
  grow: {
    flexGrow: 1,
  },
  textField: {
    width: "100%",
  },
  buttonSubmit: {
    color: "#fff",
    width: "100%",
  },
  buttonClean: {
    width: "100%",
    color: "#fff",
  },
  form: {
    margin: "30px auto",
  },
  statusBlocked: {
    height: 10,
    width: 10,
    background: "red",
    borderRadius: "50%",
    margin: "auto",
  },
  statusActive: {
    height: 10,
    width: 10,
    background: "green",
    borderRadius: "50%",
    margin: "auto",
  },
}));

export default function EnhancedTable({
  appointments,
  getAppointments,
  params,
  setParams,
  history,
}) {
  const classes = useStyles();
  const [data, setData] = useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = params.orderBy === property && params.order === "asc";
    setParams({ ...params, order: isAsc ? "desc" : "asc", orderBy: property });

    getAppointments({
      pPage: params.page,
      pRowsPerPage: params.rowsPerPage,
      pOrder: isAsc ? "desc" : "asc",
      pOrderBy: property,
    });
  };

  const handleChangePage = (event, newPage) => {
    setParams({ ...params, page: newPage });
    getAppointments({
      pPage: newPage,
      pRowsPerPage: params.rowsPerPage,
      pOrder: params.order,
      pOrderBy: params.orderBy,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setParams({
      ...params,
      rowsPerPage: parseInt(event.target.value),
      page: 0,
    });
    getAppointments({
      pPage: 0,
      pRowsPerPage: parseInt(event.target.value),
      pOrder: params.order,
      pOrderBy: params.orderBy,
    });
  };

  useEffect(() => {
    if (!isEmpty(appointments)) {
      setData(appointments.data);
    }
  }, [appointments]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table}>
            <EnhancedTableHead
              classes={classes}
              order={params.order}
              orderBy={params.orderBy}
              onRequestSort={handleRequestSort}
              rowCount={params.total}
            />
            <TableBody>
              {!isEmpty(data) &&
                data.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      className={`${classes.tableRow}`}
                      onClick={(e) =>
                        history.push(`/exames/${row.appointment.id}`)
                      }
                      style={{ textDecoration: "none" }}
                    >
                      <TableCell>
                        {moment(row.date).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell>
                        {row.appointment.user.profile.last_name}
                      </TableCell>
                      <TableCell>
                        {row.appointment.user.profile.last_name}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          component="div"
          count={params.total}
          rowsPerPage={params.rowsPerPage}
          page={params.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) =>
            `${from} - ${to} de ${count}`
          }
          labelRowsPerPage="Por página"
        />
      </Paper>
    </div>
  );
}
