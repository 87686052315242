import { Button, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { setCurrentUser } from "../../../Actions/authActions";
import { setMessage } from "../../../Actions/messageActions";
import api from "../../../Services/Api";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    display: "flex",
    height: "100vh",
    background: theme.palette.primary.main,
  },
  errorMessage: {
    fontSize: 14,
    borderRadius: 4,
    marginTop: 10,
    padding: "18px 0px",
    color: "#fff",
    background: "#ca5d5d",
    position: "absolute",
    textAlign: "center",
    width: 380,
  },
  form: {
    margin: "auto",
    maxWidth: 380,
  },
  link: {
    fontSize: 12,
    fontWeight: 300,
    textDecoration: "none",
    color: "#868686",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  linksContainer: {
    textAlign: "right",
    margin: "10px 0px 5px",
  },
  title: {
    fontSize: 22,
    fontWeight: 100,
    margin: "10px auto 20px",
    color: "#464646",
    textAlign: "left",
  },
  textField: {
    margin: "5px auto 10px",
    width: "100%",
    fontSize: 14,
  },
  buttonSubmit: {
    width: "100%",
    color: "#fff",
  },
  paper: {
    padding: "15px 30px",
  },
}));

export const Index = ({
  setMessage,
  setCurrentUser,
  isAuthenticated,
  history,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    email: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setError(false);
    setErrorMessage("");
    api
      .post("/auth/recovery", {
        email: values.email,
      })
      .then((res) => {
        setLoading(false);
        setMessage(
          "Verifique seu e-mail! Você recebeu um link para criar uma nova senha.",
          "success"
        );
        history.push("/");
      })
      .catch((err) => {
        let message = "Credenciais inválidas, tente novamente.";
        setLoading(false);
        setError(true);
        setErrorMessage(message);
      });
  };

  return (
    <div className={classes.root}>
      <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
        <Paper className={classes.paper} elevation={3}>
          <div style={{ textAlign: "center", width: "100%" }}>
            <img
              src="/img/wave-logo.png"
              style={{ width: "225px", margin: "15px auto 10px" }}
              alt="Wave Medicina Fetal"
            />
          </div>
          <p>Preencha seu email para recuperar sua senha.</p>
          <TextField
            value={values.email}
            error={error}
            id="email"
            type="email"
            label="E-mail"
            variant="outlined"
            disabled={loading}
            required
            onChange={handleChange("email")}
            classes={{
              root: classes.textField,
            }}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            className={classes.buttonSubmit}
            size="large"
          >
            Recuperar senha
          </Button>
          <div className={classes.linksContainer}>
            <Link to="/" className={classes.link}>
              Lembrei da minha senha.
            </Link>
          </div>
        </Paper>
        {errorMessage && (
          <div className={classes.errorMessage}>{errorMessage}</div>
        )}
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = { setCurrentUser, setMessage };

export default connect(mapStateToProps, mapDispatchToProps)(Index);
