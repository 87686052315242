import { SET_MESSAGE, CLEAR_MESSAGE } from "./types";

export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};

export const setMessage = (text, type) => (dispatch) => {
  dispatch({
    type: SET_MESSAGE,
    payload: {
      message: text,
      type: type,
    },
  });
};
