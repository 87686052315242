import DateFnsUtils from "@date-io/date-fns";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from "@material-ui/icons/FilterList";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import brLocale from "date-fns/locale/pt-BR";
import moment from "moment";
import React, { useEffect, useState } from "react";

import isEmpty from "./../../../../Helpers/isEmpty";

const headCells = [
  {
    id: "date",
    disablePadding: false,
    label: "Data do Exame",
  },
  {
    id: "medical_records.number",
    disablePadding: false,
    label: "Prontuário",
  },
  {
    id: "profiles.first_name",
    disablePadding: false,
    label: "Nome",
  },
  {
    id: "profiles.last_name",
    disablePadding: false,
    label: "Sobrenome",
  },
  {
    id: "status",
    align: "left",
    disablePadding: false,
    label: "Status",
  },
  {
    id: "created_at",
    align: "left",
    disablePadding: false,
    label: "Criado em",
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  legendLabels: {
    margin: "0px 0px 20px",
    "& p": {
      fontSize: 10,
      color: "#ccc",
      margin: "20px 0px 2px 3px",
    },
  },
  roleLabel: {
    padding: "4px 10px",
    margin: "2px",
    borderRadius: 4,
    fontSize: 12,
    color: "#212121",
  },
  tableRow: {
    cursor: "pointer",
  },
  grow: {
    flexGrow: 1,
  },
  textField: {
    width: "100%",
  },
  buttonSubmit: {
    color: "#fff",
    width: "100%",
  },
  buttonClean: {
    width: "100%",
    color: "#fff",
  },
  form: {
    margin: "30px auto",
  },
  statusBlocked: {
    height: 10,
    width: 10,
    background: "red",
    borderRadius: "50%",
    margin: "auto",
  },
  statusActive: {
    height: 10,
    width: 10,
    background: "green",
    borderRadius: "50%",
    margin: "auto",
  },
}));

export default function EnhancedTable({
  appointments,
  getAppointments,
  params,
  setParams,
  history,
}) {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    medicalRecordNumber: params.medicalRecordNumber,
    status: params.status,
    date: params.date,
  });

  const handleRequestSort = (event, property) => {
    const isAsc = params.orderBy === property && params.order === "asc";
    setParams({ ...params, order: isAsc ? "desc" : "asc", orderBy: property });

    getAppointments({
      pPage: params.page,
      pRowsPerPage: params.rowsPerPage,
      pOrder: isAsc ? "desc" : "asc",
      pOrderBy: property,
      pDate: values.date,
      pMedicalRecordNumber: values.medicalRecordNumber,
      pStatus: values.status,
    });
  };

  const handleChangePage = (event, newPage) => {
    setParams({ ...params, page: newPage });
    getAppointments({
      pPage: newPage,
      pRowsPerPage: params.rowsPerPage,
      pOrder: params.order,
      pOrderBy: params.orderBy,
      pDate: values.date,
      pMedicalRecordNumber: values.medicalRecordNumber,
      pStatus: values.status,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setParams({
      ...params,
      rowsPerPage: parseInt(event.target.value),
      page: 0,
    });
    getAppointments({
      pPage: 0,
      pRowsPerPage: parseInt(event.target.value),
      pOrder: params.order,
      pOrderBy: params.orderBy,
      pDate: values.date,
      pMedicalRecordNumber: values.medicalRecordNumber,
      pStatus: values.status,
    });
  };

  const handleToggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    getAppointments({
      pPage: params.page,
      pRowsPerPage: params.rowsPerPage,
      pOrder: params.order,
      pOrderBy: params.orderBy,
      pFirstName: params.firstName,
      pLastName: params.lastName,
      pDate: values.date,
      pMedicalRecordNumber: values.medicalRecordNumber,
      pStatus: values.status,
    });
    setLoading(false);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setParams({ ...params, [prop]: event.target.value });
  };

  const handleCleanForm = () => {
    setParams({
      ...params,
      date: "",
      medicalRecordNumber: "",
      status: "",
    });
    setValues({
      date: "",
      medicalRecordNumber: "",
      status: "",
    });
  };

  const handleChangeDate = (date) => {
    setValues({ ...values, date: moment(date).format("DD/MM/YYYY") });
    setParams({ ...params, date: moment(date).format("DD/MM/YYYY") });
  };

  useEffect(() => {
    if (!isEmpty(appointments)) {
      setData(appointments.data);
    }
  }, [appointments]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar>
          <div className={classes.grow}></div>
          <Tooltip
            align="right"
            title="Filtrar Pesquisa"
            onClick={(e) => handleToggleFilter()}
          >
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
        {openFilter && (
          <Container>
            <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
              <Grid container spacing={(2, 3)}>
                <Grid item sm={12} md={4}>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={brLocale}
                  >
                    <KeyboardDatePicker
                      label="Data do Exame"
                      inputVariant="outlined"
                      clearable
                      disabled={loading}
                      value={values.date === "" ? null : values.date}
                      className={classes.textField}
                      onChange={(date) => handleChangeDate(date)}
                      format="dd/MM/yyyy"
                      clearLabel="Limpar"
                      cancelLabel="Cancelar"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    value={values.medicalRecordNumber}
                    id="medicalRecordNumber"
                    label="Prontuário"
                    variant="outlined"
                    disabled={loading}
                    type="number"
                    onChange={handleChange("medicalRecordNumber")}
                    classes={{
                      root: classes.textField,
                    }}
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <FormControl variant="outlined" className={classes.textField}>
                    <InputLabel id="status-select-label">Status</InputLabel>
                    <Select
                      labelId="status-select-label"
                      id="status-select"
                      value={values.status}
                      disabled={loading}
                      onChange={handleChange("status")}
                    >
                      <MenuItem value={""}>--</MenuItem>
                      <MenuItem value={"active"}>Ativo</MenuItem>
                      <MenuItem value={"blocked"}>Bloqueado</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={6}>
                  <Button
                    variant="contained"
                    className={classes.buttonClean}
                    size="large"
                    disabled={loading}
                    onClick={(e) => handleCleanForm()}
                  >
                    Limpar
                  </Button>
                </Grid>
                <Grid item sm={12} md={6}>
                  <Button
                    color="secondary"
                    type="submit"
                    variant="contained"
                    className={classes.buttonSubmit}
                    size="large"
                    disabled={loading}
                  >
                    Pesquisar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        )}
        <TableContainer>
          <Table className={classes.table}>
            <EnhancedTableHead
              classes={classes}
              order={params.order}
              orderBy={params.orderBy}
              onRequestSort={handleRequestSort}
              rowCount={params.total}
            />
            <TableBody>
              {!isEmpty(data) &&
                data.map((row, index) => {
                  let statusClass = "";
                  if (row.status === "active") {
                    statusClass = classes.statusActive;
                  }
                  if (row.status === "blocked") {
                    statusClass = classes.statusBlocked;
                  }
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      className={`${classes.tableRow}`}
                      onClick={(e) => history.push(`/exames/${row.id}`)}
                      style={{ textDecoration: "none" }}
                    >
                      <TableCell>
                        {moment(row.date).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell>{row.medicalRecord.number}</TableCell>
                      <TableCell>{row.user.profile.first_name}</TableCell>
                      <TableCell>{row.user.profile.last_name}</TableCell>
                      <TableCell align="right">
                        <div className={`${statusClass}`} />
                      </TableCell>
                      <TableCell align="right">
                        {moment(row.created_at).format("DD/MM/YYYY - HH:mm:ss")}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          component="div"
          count={params.total}
          rowsPerPage={params.rowsPerPage}
          page={params.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) =>
            `${from} - ${to} de ${count}`
          }
          labelRowsPerPage="Por página"
        />
      </Paper>
    </div>
  );
}
