import { Breadcrumbs, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import isEmpty from "../../../../Helpers/isEmpty";
import api from "../../../../Services/Api";
import { setMessage } from "./../../../../Actions/messageActions";
import Loading from "./../../../../Components/Loading";
import TableAppointments from "./TableAppointments";
import TableAppointmentsClient from "./TableAppointmentsClient";
import TableAppointmentsDoctor from "./TableAppointmentsDoctor";

export const Index = ({ setMessage, user, history, role }) => {
  const [loading, setLoading] = useState(false);
  const [appointments, setAppointments] = useState({});
  const [params, setParams] = useState({
    order: "desc",
    orderBy: "created_at",
    page: 0,
    rowsPerPage: 50,
    total: 0,
    date: "",
    medicalRecordNumber: "",
    status: "",
  });

  const getAppointments = ({
    pOrder,
    pOrderBy,
    pPage,
    pRowsPerPage,
    pDate,
    pMedicalRecordNumber,
    pStatus,
  }) => {
    setLoading(true);
    api
      .get("appointments", {
        params: {
          order: pOrder,
          orderBy: pOrderBy,
          page: pPage,
          rowsPerPage: pRowsPerPage,
          date: pDate,
          medicalRecordNumber: pMedicalRecordNumber,
          status: pStatus,
        },
      })
      .then((res) => {
        if (!isEmpty(res.data)) {
          setAppointments(res.data);
          setParams({
            ...params,
            total: parseInt(res.data.total),
            page: res.data.page - 1,
            rowsPerPage: res.data.perPage,
          });
        } else {
          setMessage("Erro ao fazer a pesquisa, tente novamente!", "error");
        }
        setLoading(false);
      })
      .catch((err) => {
        setMessage("Você não tem permissão para ver o exame!", "error");
      });
  };

  useEffect(() => {
    setLoading(true);
    if (!isEmpty(user)) {
      api
        .get("appointments")
        .then((res) => {
          setAppointments(res.data);
          setLoading(false);
          setParams({
            ...params,
            total: parseInt(res.data.total),
            page: res.data.page - 1,
            rowsPerPage: res.data.perPage,
          });
        })
        .catch((err) => {
          setMessage("Você não tem permissão para ver exames!", "error");
          history.push("/painel");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/painel">Painel</Link>
        <Typography>Exames</Typography>
      </Breadcrumbs>
      <Typography variant="h1">Exames</Typography>
      {role === "admin" ||
        (role === "employee" && <p>Lista de todos os exames do sistema.</p>)}
      {role === "client" && <p>Seus exames feitos na clínica.</p>}
      {role === "doctor" && (
        <p>Lista de todos os exames de seus pacientes feitos na clínica.</p>
      )}
      {loading && <Loading />}
      {!loading && (role === "admin" || role === "employee") && (
        <TableAppointments
          appointments={appointments}
          getAppointments={getAppointments}
          params={params}
          setParams={setParams}
          history={history}
        />
      )}
      {!loading && role === "doctor" && (
        <TableAppointmentsDoctor
          appointments={appointments}
          getAppointments={getAppointments}
          params={params}
          setParams={setParams}
          history={history}
        />
      )}
      {!loading && role === "client" && (
        <TableAppointmentsClient
          appointments={appointments}
          getAppointments={getAppointments}
          params={params}
          setParams={setParams}
          history={history}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  role: state.auth.role.slug,
});

const mapDispatchToProps = { setMessage };

export default connect(mapStateToProps, mapDispatchToProps)(Index);
