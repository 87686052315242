import api from "./../Services/Api";

const setAuthToken = token => {
  if (token) {
    // Apply to every request
    api.defaults.headers.common["Authorization"] = "Bearer " + token;
  } else {
    // Delete auth header
    delete api.defaults.headers.common["Authorization"];
  }
};

export default setAuthToken;
